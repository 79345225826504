<template>
  <div>
    <div class="dirSelectWra" v-show="isShow">
      <div class="mainWra">
        <div class="leftNav" >
          <div
            class="leftItem"
            :class="{ leftSelected: isShow === '全部' }"
            @click="clearAll"
          >
            全部
          </div>
          <div
            class="leftItem"
            v-for="(item, index) in subjectNames"
            :key="index"
            :class="{ leftSelected: isShow === item }"
            @click="leftClick(item)"
          >
            <div>
            {{ item
            }}{{
              subjectSelectedNum[item] > 0
                ? ' (已选' + subjectSelectedNum[item] + ')'
                : ''
            }}
            </div>
          </div>
        </div>
        <div class="mainSelects">
          <div class="mainItem" @click="mainAll">全部(可多选)</div>
          <div
            class="mainItem"
            :class="{
              mainItemSelected: select.directions.indexOf(item.id) !== -1
            }"
            @click="mainClick(item)"
            v-for="(item, index) in showSubjects[isShow]"
            :key="index"
          >
            {{ item.content }}
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="footerBtn" @click="reset">重置</div>
        <div class="footerBtn" @click="sure">确定</div>
      </div>
    </div>
    <div class="overlay" @click="sure"></div>
  </div>
</template>

<script>
import axios from '../../axios'

export default {
  name: 'directionSelect',
  props: {
    select: {},
    type: {}
  },
  data () {
    return {
      isShow: '',
      subjects: [],
      showSubjects: {
        理科: [],
        工科: [],
        商科社科: [],
        全部: []
      },
      subjectNames: ['理科', '工科', '商科社科'],
      subjectSelectedNum: {
        理科: 0,
        工科: 0,
        商科社科: 0
      },
      mainSelected: {}
    }
  },
  mounted () {
    this.getSubject()
    document
      .getElementsByTagName('body')[0]
      .style.setProperty(
        '--label-font-color',
        this.type === 'thesis' ? '#D0AF8B' : '#5BB680'
      )
  },
  methods: {
    getSubject () {
      axios.get('enterpise/tag_list').then(res => {
        const tag = res.data.tag
        let i = 0
        for (const item of this.subjectNames) {
          this.showSubjects[item] = tag[i][item]
          i++
        }
        this.initSelectedNum()
      })
    },
    leftClick (item) {
      this.isShow = item
    },
    clearAll () {
      this.select.directions = []
      this.mainSelected = {}
      this.isShow = '全部'
      this.subjectSelectedNum = {
        理科: 0,
        工科: 0,
        商科社科: 0
      }
    },
    reset () {
      this.clearAll()
      this.sure()
    },
    mainClick (item) {
      const key = this.select.directions.indexOf(item.id)
      if (key === -1) {
        this.select.directions.push(item.id)
        this.subjectSelectedNum[this.isShow]++
      } else {
        this.select.directions.splice(key, 1)
        this.subjectSelectedNum[this.isShow]--
      }
    },
    mainAll () {
      for (const dir of this.showSubjects[this.isShow]) {
        if (this.select.directions.indexOf(dir.id) === -1) {
          this.select.directions.push(dir.id)
          this.subjectSelectedNum[this.isShow]++
        }
      }
    },
    sure () {
      this.$emit('directionSelect', '')
    },
    initSelectedNum () {
      let a = {}
      const that = this
      for (const name in this.subjectSelectedNum) {
        a[name] = []
        a[name] = this.showSubjects[name].map(item => {
          return item.id
        })
        this.subjectSelectedNum[name] = a[name].filter(item =>
          that.select.directions.includes(item)
        ).length
      }
      for (const subjectSelectedNumKey in this.subjectSelectedNum) {
        if (this.subjectSelectedNum[subjectSelectedNumKey] > 0) {
          this.isShow = subjectSelectedNumKey
          return
        }
      }
      this.isShow = '全部'
    }
  }
}
</script>

<style scoped lang="scss">
$labelFontColor: var(--label-font-color, #d0af8b);
@media screen and (max-width: 600px){
  .dirSelectWra {
    width: 100vw;
  }
  .overlay{
    width: 100vw;
  }
}
@media screen and (min-width: 601px){
  .dirSelectWra {
    width: 400px;
    margin: 0 auto;
  }
  .overlay{
    width: 400px;
  }
}
.dirSelectWra {
  height: 308rem * 0.04;
  background-color: #f6f8fb;
  z-index: 3;
  position: fixed;
  top: 83rem * 0.04;
  .mainWra {
    width: 100%;
    height: 230rem * 0.04;
    display: flex;
    justify-content: left;
    .leftNav {
      box-sizing: border-box;
      padding-top: 15rem * 0.04;
      width: 24%;
      height: 100%;
      background-color: #e4e5e7;

      .leftItem {
        box-sizing: border-box;
        font-size: 12rem * 0.04;
        height: 40rem * 0.04;
        line-height: 20rem * 0.04;
        margin-bottom: 4rem * 0.04;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .leftSelected {
        background-color: #fff;
        color: $labelFontColor;
      }
    }
    .mainSelects {
      text-align: left;
      width: 74%;
      height: 100%;
      background-color: inherit;
      box-sizing: border-box;
      padding-top: 15rem * 0.04;
      overflow: scroll;

      .mainItem {
        width: 100%;
        box-sizing: border-box;
        height: 40rem * 0.04;
        line-height: 40rem * 0.04;
        padding: 0 12rem * 0.04;
        font-size: 12rem * 0.04;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .mainItemSelected {
        background-color: #fff;
        color: $labelFontColor;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-around;
    padding: 20rem * 0.04 0 0;
    .footerBtn {
      width: 100rem * 0.04;
      height: 30rem * 0.04;
      border-radius: 8rem * 0.04;
      font-size: 12rem * 0.04;
      line-height: 30rem * 0.04;
      color: #fff;
      background-color: #656d78;
      text-align: center;
      &:last-child {
        background-color: $labelFontColor;
      }
    }
  }
}
.overlay {
  position: fixed;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
}
</style>
