<template>
  <div>
    <div id="header" :style="`background-image:url('${img}')`"></div>
    <div class="body">
      <div class="txtcenter">
        <el-divider content-position="left">【{{ subject }}】</el-divider>
        <p>{{ title }}</p>
      </div>
      <div>
        <div class="divide">项目介绍</div>
        <div class="divide2"></div>
        <div class="msg">
          <div class="line" v-for="item in msg" :key="item.title">
            <p>
              <b>{{ item.title }}</b>
              <span>{{ item.content }}</span>
            </p>
          </div>
        </div>
        <div>
          <div class="divide">涉及领域</div>
          <div class="divide2"></div>
          <div>
            <span>
              本课题涉及到<b> {{ involve }} </b>等方面的知识，适合申请
              <b> {{ direction }} </b>
              等相关专业的学生
            </span>
          </div>
        </div>
        <div>
          <div class="divide">项目成果</div>
          <div class="divide2"></div>
          <div class="msg">
            <div class="line" v-for="item in results" :key="item">
              <p>
                <span>{{ item }}</span>
              </p>
            </div>
          </div>
        </div>
        <div>
          <div class="divide">研究前沿性</div>
          <div class="divide2"></div>
          <div>
            <div class="jiao1" />
            <div style="padding:0.213333rem 1em 0.213333rem">
              <template v-for="content in frontier">
                <p style="text-align: justify;">
                  <span>
                    {{ content }}
                  </span>
                </p>
                <p>&nbsp;</p>
              </template>
            </div>
            <div class="jiao2" />
          </div>
        </div>
        <div>
          <div class="divide">师资背景</div>
          <div class="divide2"></div>
          <div class="msg">
            <div class="line" v-for="item in teacher" :key="item">
              <p>
                <span>{{ item }}</span>
              </p>
            </div>
          </div>
        </div>
        <div>
          <div class="divide">1v1定制化辅导参考任务</div>
          <div class="divide2"></div>
          <div class="task">
            <div class="tag">
              <p><span>科研阶段</span></p>
            </div>
            <div class="task-content">
              <p class="content">
                <span>学习文献查阅方法，完成背景调研，确定研究问题</span>
              </p>
              <p class="content" v-for="item in task" :key="item">
                <span>{{ item }}</span>
              </p>
            </div>
            <div v-for="item in write" :key="item.x">
              <div class="tag">
                <p>
                  <span>{{ item.x }}</span>
                </p>
              </div>
              <div class="task-content">
                <p>
                  <span style="color:#d6ae86">{{ item.y }}</span>
                </p>
                <p class="content" v-for="s in item.z" :key="s">
                  <span>{{ s }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="tip">
            <p>
              <span> （以上任务仅供参考，实际辅导根据定制化要求展开）</span>
            </p>
          </div>
          <div>
            <div class="divide">科研补充包详情</div>
            <div class="divide2"></div>
            <div class="file">
              <div class="pdf" @click="download(0)">
                <img src="../assets/pdf文件.png" />
                <div>
                  <p>48课时科研基础课详情.pdf</p>
                  <span>150.11 KB</span>
                </div>
              </div>
              <div class="pdfdivide"></div>
              <div class="pdf" @click="download(1)">
                <img src="../assets/pdf文件.png" />
                <div>
                  <p>25课时学术写作强化课详情.pdf</p>
                  <span>744.07 KB</span>
                </div>
              </div>
            </div>
          </div>
          <div class="supply">
            <p>
              <span>
                (注:补充包所涉及课程，旨在为参与项目的学生强化学术基础，将与1v1正式科研穿插进行，当补充包课程与1v1核心专业指导时间冲突时，以1v1的指导安排为主。
              </span>
            </p>
            <p><span> 补充课程的具体安排安排及内容洋情参考附件。)</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios'
export default {
  name: 'App',
  data () {
    return {
      subjectE: 'ECONOMICS',
      msg: [
        { title: '正式科研：', content: '1v1线上定制辅导' },
        { title: '项目时长：', content: '3~6个月' },
        { title: '开始时间：', content: '全年滚动招生，随时开始' },
        {
          title: '学术补充包：',
          content: '48课时科研基础课+ 25课时学术写作强化课'
        }
      ],
      results: [
        '独立作者的CPCI收录会议论文',
        '国际认可的论文录用证明',
        '专属个性化导师推荐信',
        '定制化学术研究经历'
      ],
      write: [
        {
          x: '写作阶段一',
          y: '论文先导课',
          z: ['论文基础知识讲解', '如何快速阅读论文', '论文的结构与写作技巧']
        },
        {
          x: '写作阶段二',
          y: '论文的选题与提纲',
          z: [
            '梳理相关知识体系，阅读大量文献，完成读书笔记',
            '与指导老师讨论论文方向及具体选题',
            '文献整理并草拟论文提纲'
          ]
        },
        {
          x: '写作阶段三',
          y: '论文开题答辩',
          z: [
            '由学术委员会举办开题答辩并由学生进行汇报',
            '学生根据答辩反馈完善论文提纲'
          ]
        },
        {
          x: '写作阶段四',
          y: '正文撰写',
          z: [
            '根据论文提纲明确写作内容及逻辑',
            '根据指导老师要求完成论文各部分内容撰写',
            '完成必要的理论模型建立、案例分析、数据处理及分析、理论讨论等内容',
            '整理完善全文'
          ]
        },
        {
          x: '写作阶段五',
          y: '论文优化及投递',
          z: [
            '论文翻译或润色（非必要流程）',
            '论文查重及降重',
            '论文选会及投递',
            '根据返修意见优化论文直至论文接收',
            '收获录用证明',
            '按照要求完成会议注册及缴费（此部分费用根据会议收费标准及最终论文版面情况决定，请作者自行缴纳，不包含在项目指导费用中）'
          ]
        }
      ],
      OSS: process.env.VUE_APP_OSS,
      img: '',
      subject: '',
      title: '',
      involve: '',
      direction: '',
      frontier: [],
      teacher: [],
      task: [],
      pdf: []
    }
  },
  mounted () {
    let x = document.body.clientWidth
    document.documentElement.style.fontSize = x / 16 + 'px'
    window.onresize = () => {
      return (() => {
        let x = document.body.clientWidth
        document.documentElement.style.fontSize = x / 16 + 'px'
      })()
    }
    this.pdf = [
      `${this.OSS}/pages/48课时科研基础课详情.pdf`,
      `${this.OSS}/pages/25课时学术写作强化课详情.pdf`
    ]
  },
  created () {
    let path = this.$route.fullPath
    let uuid = path.split('/').pop()
    axios
      .get('previews/get_acpage', {
        params: {
          uuid: uuid
        }
      })
      .then(res => {
        let data = res.data
        this.subject = data.subject
        this.frontier = data.frontier
        this.title = data.title
        this.img = data.img
        this.teacher = data.teacher
        this.teacher.splice(1, 0, 'EI/CPCI国际学术会议委员会成员')
        this.task = data.task
        this.involve = data.involve
        this.direction = data.direction
        document.title = this.title
      })
      .catch(() => {
        this.$router.push('/acpage')
      })
  },
  methods: {
    download (index) {
      let routeData = this.$router.resolve({
        path: '/pdf',
        query: {
          url: this.pdf[index]
        }
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
#header {
  width: 16rem;
  height: 16.0104rem;
  position: relative;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  .background {
    width: 16rem;
    z-index: -1;
  }
  span {
    color: #fff;
    font-size: 1.5rem;
    position: absolute;
    left: 0.7rem;
    bottom: 12rem;
    font-weight: bolder;
    line-height: 1.5rem;
    font-family: LemonMilkbold;
    max-width: 9rem;
  }
  img {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 16rem;
  }
}
.body {
  padding: 0 0.68266667rem;
  text-align: left;
  font-size: 0.68266667rem;
  span {
    font-size: 0.597333rem;
    color: #2b2b2b;
  }
  p {
    font-size: 0.68266667rem;
    line-height: 1.6;
  }
}
.el-divider__text.is-left {
  left: 0;
}
.el-divider {
  background-color: #d6ae86;
  position: relative;
  &::before {
    content: '';
    width: 0.256rem;
    height: 0.256rem;
    border-radius: 100%;
    position: absolute;
    right: -0.128rem;
    top: -0.128rem;
    background-color: #dbb289;
  }
}
.el-divider--horizontal {
  margin: 1.4666667rem 0;
}
.el-divider__text {
  font-size: 0.768rem;
  color: #d6ae86;
  width: max-content;
}
.txtcenter {
  position: relative;
  padding: 0rem 0.426667rem 0.426667rem;
  p {
    text-align: left;
    font-size: 0.853rem;
    padding: 0.0853333rem 0.426667rem 0.0853333rem;
    color: rgb(0, 0, 0);
    font-weight: bolder;
    border-bottom: 0.128rem solid #d6ae86;
    display: inline-block;
    width: -webkit-fill-available;
  }
}
b {
  font-weight: bolder;
  color: #d6ae86;
  font-size: 0.597333rem;
}
span {
  font-size: 0.597333rem;
}
.divide {
  margin-top: 2.68266667rem;
  border-top: 1px solid rgb(219, 178, 137);
  border-bottom: 1px solid rgb(219, 178, 137);
  box-sizing: border-box;
  text-align: center;
  color: rgb(219, 178, 137);
  font-size: 0.768rem;
  font-weight: bold;
  letter-spacing: 2px;
  padding: 0.426667rem 0rem 0.426667rem;
  box-sizing: border-box;
}
.divide2 {
  margin: 0.426667rem auto 1.426667rem;
  height: 0rem;
  width: 0rem;
  border-width: 0.341333rem;
  border-style: solid;
  border-image: initial;
  border-color: rgb(219, 178, 137) transparent transparent;
  box-sizing: border-box;
}
.msg {
  padding-top: 0.68266667rem;
  .line {
    color: rgb(102, 102, 102);
    padding-left: 1.49333333rem;
    text-align: justify;
    p {
      display: list-item;
      &::marker {
        unicode-bidi: isolate;
        font-variant-numeric: tabular-nums;
        text-transform: none;
        text-indent: 0px !important;
        text-align: start !important;
        text-align-last: start !important;
      }
    }
  }
}
.task {
  border-left: 0.0853333rem solid rgb(214, 174, 134);
  .tag {
    display: inline-block;
    padding: 0.170667rem 0.170667rem 0.170667rem;
    margin-bottom: 0.341333rem;
    background-color: rgb(214, 174, 134);
    border-radius: 0rem 0.0853333rem 0.0853333rem 0rem;
    span {
      color: #fff;
      font-weight: bolder;
    }
  }
  .task-content {
    padding-left: 0.597333rem;
    padding-bottom: 1.024rem;
    .content {
      display: list-item;
      color: rgb(102, 102, 102);
      margin-left: 0.597333rem;
      text-align: justify;
      &::marker {
        unicode-bidi: isolate;
        font-variant-numeric: tabular-nums;
        text-transform: none;
        text-indent: 0px !important;
        text-align: start !important;
        text-align-last: start !important;
      }
    }
  }
}

.tip {
  font-style: italic;
}
.file {
  margin-top: 0.68266667rem;
  box-shadow: 0 0.08533333rem 0.34133333rem 0 rgba(0, 0, 0, 0.15);
  border-radius: 0.256rem;
  overflow: hidden;
  position: relative;
  display: inline-block;
}
.pdf {
  padding: 0.68266667rem;
  background-color: #fff;
  display: flex;
  cursor: pointer;
  div {
    flex: 1;
    padding-top: 0.256rem;
    margin-left: 0.68266667rem;
    p {
      font-size: 0.68266667rem;
      color: rgba(0, 0, 0, 0.87);
      line-height: 1.3;
      display: block;
      word-break: break-word;
    }
    span {
      font-size: 0.512rem;
      color: #9b9b9b;
      display: flex;
      margin-top: 0.34133333rem;
    }
  }
  img {
    width: 2.048rem;
    height: 2.56rem;
    background-size: 2.048rem 2.56rem;
    background-repeat: no-repeat;
  }
}
.pdfdivide {
  display: block;
  position: absolute;
  top: 50%;
  left: 0.68266667rem;
  right: 0.68266667rem;
  border-top: 0.04267rem solid rgba(120, 130, 140, 0.25);
  z-index: 100;
}
.supply {
  padding: 0.68266667rem 0;
  font-style: italic;
}
.jiao1 {
  width: 1.2em;
  height: 1.2em;
  border-top: 0.0853333rem solid rgb(219, 178, 137);
  border-left: 0.0853333rem solid rgb(219, 178, 137);
  margin-bottom: -0.256rem;
}
.jiao2 {
  width: 1.2em;
  height: 1.2em;
  border-bottom: 0.0853333rem solid rgb(219, 178, 137);
  border-right: 0.0853333rem solid rgb(219, 178, 137);
  margin-left: auto;
}
</style>
