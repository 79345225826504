<template>
  <div>
    <div style="position:relative">
      <img class="poster" :src="file" />
      <div class="meng"></div>
      <img class="logo" src="./page/XT.png" />
      <div class="direction"># {{ direction_tag }}</div>
      <div class="heng"></div>
      <div class="mengTitle">
        {{ title }}
      </div>
      <img src="./page/kuang.png" class="kuang" />
      <img src="./page/zi.png" class="zi" />
    </div>
    <div class="main">
      <div class="divide">
        <div class="line"></div>
        <div class="content">项目收获</div>
      </div>
      <div class="got">
        <div v-for="item in got" :key="item[0]">
          <img src="./img/gou.png" />
          {{ item[0] }}
          <span v-if="item[1]">可用于申请</span>
        </div>
      </div>
      <div class="divide">
        <div class="line"></div>
        <div class="content">适合人群</div>
      </div>
      <div class="people">
        <div class="content">
          有意提高自身知识水平及学术能力的学生；有意掌握最前沿科研热点及科研方法的学生；有升学意向、跨专业深造的学生。
        </div>
        <div>
          <p>适合年级：{{ get_degree() }}</p>
          <p>
            适合专业：
            <span v-for="item in tags" :key="item" class="tag">
              {{ item }}
            </span>
          </p>
          <p>项目会涉及到{{ method }}。学生需要具备{{ ability }}。</p>
        </div>
      </div>
      <div class="divide">
        <div class="line"></div>
        <div class="content">项目特色</div>
      </div>
      <div class="people">
        <p>高效1V1：因材施教，大幅节约时间</p>
        <p>独一无二：拒绝班课及模板推荐，让推荐信真正具有意义</p>
        <p>直达标准：审稿人亲自辅导，一次达标，不走弯路</p>
      </div>
      <div class="divide">
        <div class="line"></div>
        <div class="content">导师介绍</div>
      </div>
      <div class="teacher">
        <p>推荐信身份：{{ identity }}</p>
        <p>EI/CPCI国际学术会议委员会成员</p>
        <div class="content">
          {{ paper }}
        </div>
      </div>
      <div class="divide">
        <div class="line"></div>
        <div class="content">项目背景</div>
      </div>
      <div class="back">
        {{ back }}
      </div>
      <div class="divide">
        <div class="line"></div>
        <div class="content">项目简介</div>
      </div>
      <div class="introduce">
        {{ introduce }}
      </div>
      <div class="divide">
        <div class="line"></div>
        <div class="content">参考学习内容</div>
      </div>
      <div class="refer">
        <div class="re">
          <p v-for="item in task" :key="item[0]">{{ item[0] }}</p>
        </div>
        <div v-for="(item, index) in tasks" :key="item.title" class="task">
          <div class="left">
            <div class="circle"></div>
            <div class="line" v-if="index !== tasks.length - 1"></div>
          </div>
          <div style="color:#D7AF87;">{{ item.title }}</div>
          <p v-for="value in item.value" :key="value">{{ value }}</p>
          <p><span style="color:#d7af87">任务：</span>{{ item.duty }}</p>
        </div>
      </div>
      <div class="tips">（以上任务仅供参考，实际辅导根据定制化要求展开）</div>
      <div class="files">
        附件
        <div class="fileList">
          <img src="./img/suo.png" class="suo" />
          <div
              class="file"
              v-for="item in files"
              :key="item"
              @click="download(item)"
          >
            <img class="pdf" src="./img/pdf.png" />
            {{ item }}
            <img class="down" src="./img/down.png" />
          </div>
        </div>
      </div>
      <div class="tips">
        （注：补充包所涉及课程，旨在为参与项目的学生强化学术基础，将与1v1正式科研穿插进行，当补充包课程与1v1核心专业指导时间冲突时，以1v1的指导安排为主。
        补充课程的具体安排安排及内容详情参考附件。）
      </div>
    </div>
  </div>
</template>
<script>
import axios from '../axios'
export default {
  data () {
    return {
      got: [
        ['独立一作EI/CPCI或同级别国际学术会议全文', true],
        ['国际认可的论文录用证明', true],
        ['专属科研推荐信', true],
        ['10周在线一对一科研学习，约40学时', false],
        ['定制化学术研究经历', false],
        ['学术演讲机会及演讲证明', true],
        ['14课时学术写作课（必看）', false],
        ['73课时科研基础课（选看）', false]
      ],
      tasks: [
        {
          title: '「Week 1」',
          value: [
            '学习科研基础知识，包括什么是科研、什么是论文、如何开始科研项目等',
            '学习文献查找和阅读方法与技巧、文献管理方法与技巧、常见选题思路等'
          ],
          duty: '完成读书笔记，并拟定科研方向和选题'
        },
        {
          title: '「Week 2」',
          value: [
            '学习了解科研方向下最新研究方向以及相应研究方法，并有针对性阅读更多文献'
          ],
          duty: '精细阅读，并完成项目选题'
        },
        {
          title: '「Week 3-5」',
          value: ['分拆科研任务，并逐步进行科研探究，总结相关收获'],
          duty: '完成科研任务，并草拟论文提纲'
        },
        {
          title: '「Week 6-8」',
          value: [
            '逐步进行科研探究，总结相关收获',
            '学习论文基础写作范式，以及各部分写作内容及技巧'
          ],
          duty: '初步完成论文各部分内容内容写作'
        },
        {
          title: '「Week 9」',
          value: ['学习参考文献使用方法及技巧', '学习论文摘要写作方法及技巧'],
          duty: '优化全文，并整理添加参考文献'
        },
        {
          title: '「Week 10」',
          value: [
            '整理全文，在助教的指导下修改论文格式及降重等工作，并进行论文投递'
          ],
          duty: '修改格式并投递论文，最终收获录用证明'
        }
      ],
      tags: [],
      task: [],
      files: [
        '项目流程详情-Standard',
        '导师简历',
        '专属科研推荐信范例',
        'EI/CPCI国际学术会议录用证明范例',
        '学员案例:论文范例+学员反馈+启航系统展示',
        'FAQ-Standard',
        '14学时学术写作课详情（必看）',
        '73学时科研基础课详情'
      ],
      uuid: '',
      title: '',
      direction_tag: '',
      degree: '',
      method: '',
      ability: '',
      identity: '',
      paper: '',
      back: '',
      introduce: '',
      standard: '',
      file:'',
      resume: ''
    }
  },
  created () {
    let path = this.$route.path
    let uuid = path.split('/').pop()
    axios
        .get('previews/get_xt', {
          params: {
            uuid: uuid
          }
        })
        .then(res => {
          Object.assign(this.$data, res.data)
          document.title = this.title
        })
  },
  mounted () {
    let x = document.body.clientWidth
    document.documentElement.style.fontSize = x / 15 + 'px'
    window.onresize = () => {
      return (() => {
        let x = document.body.clientWidth
        document.documentElement.style.fontSize = x / 15 + 'px'
      })()
    }
  },
  methods: {
    itemsplit (item) {
      item = item.slice(1)
      item = item.map((e, index) => {
        if (index < item.length - 1) {
          e += '；'
        } else e += '。'
        return e
      })
      return item
    },
    get_degree () {
      switch (this.degree) {
        case '5':
          return '研究生'
        case '4':
          return '本科生/研究生'
        default:
          return '高中生/本科生/研究生'
      }
    },
    download (item) {
      item = item.replace('/', '_')
      let url = `https://elitebackground.oss-cn-zhangjiakou.aliyuncs.com/poster/Standard/${item}.pdf`
      if (item === '导师简历') {
        url = this.resume
      }
      let routeData = this.$router.resolve({
        path: '/pdf2',
        query: {
          url: url
        }
      })
      if (/Android|webOS|iPhone|ipad/i.test(navigator.userAgent)) {
        window.open(routeData.href, '_blank')
      } else {
        window.open(url, '_blank')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.main {
  padding: 0 20rem * 0.04;
  padding-bottom: 90rem * 0.04;
}

.poster {
  width: 375rem * 0.04;
  height: 210rem * 0.04;
  object-fit: cover;
  object-position: center;
}

.meng {
  width: 375rem * 0.04;
  height: 210rem * 0.04;
  position: absolute;
  top: 0px;
  z-index: 0;
  background: rgba(#040000, 0.6);
}

.logo {
  position: absolute;
  top: 26rem * 0.04;
  left: 37rem * 0.04;
  height: 30rem * 0.04;
}

.title {
  margin-top: 30rem * 0.04;
  font-family: PFBD;
  font-style: normal;
  font-weight: 600;
  font-size: 16rem * 0.04;
  line-height: 24rem * 0.04;
  text-align: justify;
  color: #555555;
}

.divide {
  font-family: PFBD;
  font-style: normal;
  font-weight: 600;
  font-size: 12rem * 0.04;
  line-height: 16rem * 0.04;
  margin-top: 30rem * 0.04;
  margin-bottom: 10rem * 0.04;
  color: #d7af87;
  height: 14rem * 0.04;
  position: relative;

.line {
  top: 7rem * 0.04;
  border-top: 1rem * 0.04 solid #d7af87;
  position: absolute;
  width: 100%;
}

.content {
  margin-left: 10rem * 0.04;
  background: #fff;
  padding: 0 10rem * 0.04;
  position: absolute;
  font-family: PFBD;
  font-style: normal;
  font-weight: 600;
  font-size: 12rem * 0.04;
  line-height: 16rem * 0.04;
  color: #d7af87;
}
}

.table {
  width: 100%;
  font-size: 8rem * 0.04;

.tableLi {
  display: flex;
  line-height: 15rem * 0.04;
  padding: 0 5rem * 0.04;

div {
  width: 33%;
  box-sizing: border-box;
  padding: 5rem * 0.04 6rem * 0.04;
}
}

.tableLi.onlyLine {
  justify-content: space-around;

div {
  width: 100%;
  text-align: center;
}
}

.tableLi.two {
div:last-child {
  width: 66%;
}
}

.tableLi.yellow {
  background-color: rgba(215, 175, 135, 0.2);
}

.tableTitle {
  font-size: 11rem * 0.04;
}
}

.got {
  font-family: PFBD;
  font-style: normal;
  font-weight: 400;
  font-size: 12rem * 0.04;
  line-height: 20rem * 0.04;

.more {
  font-size: 10rem * 0.04;
  display: inline;
  line-height: 20rem * 0.04;
}

span {
  color: #d7af87;
  font-size: 10rem * 0.04;
}

img {
  margin-right: 5rem * 0.04;
  width: 9.74rem * 0.04;
}
}

.people {
  font-family: PFBD;
  font-style: normal;
  font-weight: 400;
  font-size: 12rem * 0.04;
  line-height: 20rem * 0.04;
  color: #000000;

.content {
  margin-bottom: 5rem * 0.04;
}

.tag {
  height: 18rem * 0.04;
  padding: 0 5rem * 0.04;
  line-height: 18rem * 0.04;
  background: #d7af87;
  margin-right: 10rem * 0.04;
  display: inline-block;
  color: #fff;
}
}

p {
  display: list-item;
  position: relative;
  left: 2em;
  width: calc(100% - 2em);
  line-height: 20rem * 0.04;
}

.teacher {
  font-family: PFBD;
  font-style: normal;
  font-weight: 400;
  font-size: 12rem * 0.04;
  line-height: 20rem * 0.04;
  text-align: justify;
  color: #000000;
  white-space: pre-line;

.content {
  margin-top: 5rem * 0.04;
}
}

.back {
  font-family: PFBD;
  font-style: normal;
  font-weight: 400;
  font-size: 10rem * 0.04;
  line-height: 16rem * 0.04;
  text-align: justify;
  color: #000000;
  white-space: pre-line;
}

.introduce {
  font-family: PFBD;
  font-style: normal;
  font-weight: 400;
  font-size: 10rem * 0.04;
  line-height: 16rem * 0.04;
  text-align: justify;
  color: #000000;
  white-space: pre-line;
}

.refer {
  font-family: PFBD;
  font-style: normal;
  font-weight: 500;
  font-size: 10rem * 0.04;
  line-height: 16rem * 0.04;
  color: #000000;

p {
  line-height: 14rem * 0.04;
  font-weight: 400;
}

.re {
  margin-left: 18rem * 0.04;
  margin-bottom: 10rem * 0.04;
}

.task {
  padding-left: 18rem * 0.04;
  position: relative;
  padding-bottom: 15rem * 0.04;
}

.left {
  position: absolute;
  left: 0;
  top: 0.5em;
  height: 100%;
}

.circle {
  height: 5rem * 0.04;
  width: 5rem * 0.04;
  border-radius: 50%;
  background: #ffffff;
  border: 1rem * 0.04 solid #d7af87;
  box-sizing: border-box;
}

.line {
  border-left: 1rem * 0.04 solid #d7af87;
  height: 100%;
  width: 0;
  position: absolute;
  left: 2rem * 0.04;
}
}

.tips {
  font-family: PFBD;
  font-style: normal;
  font-weight: 400;
  font-size: 12rem * 0.04;
  line-height: 16rem * 0.04;
//text-align: center;
  color: #a7a7a7;
}

.files {
  font-family: PFBD;
  font-style: normal;
  font-weight: 600;
  font-size: 12rem * 0.04;
  line-height: 16rem * 0.04;
  color: #d7af87;
  margin-top: 30rem * 0.04;
  margin-bottom: 10rem * 0.04;

.fileList {
  border: 1rem * 0.04 solid #d7af87;
  margin-top: 14.5rem * 0.04;
  padding: 10.5rem * 0.04 19.5rem * 0.04 21rem * 0.04;
  position: relative;

.suo {
  position: absolutez;
  top: -4rem * 0.04;
  left: 45rem * 0.04;
  padding: 0 5rem * 0.04;
  width: 15rem * 0.04;
  background: #fff;
}

.file {
  background: #ffffff;
  box-shadow: 0rem * 0.04 0rem * 0.04 10rem * 0.04 rgba(0, 0, 0, 0.25);
  padding: 14rem * 0.04 30rem * 0.04 15rem * 0.04 32rem * 0.04;
  margin-top: 11rem * 0.04;
  font-family: PFBD;
  font-style: normal;
  font-weight: 400;
  font-size: 10rem * 0.04;
  line-height: 14rem * 0.04;
  color: #000000;
  position: relative;

.pdf {
  width: 20rem * 0.04;
  position: absolute;
  left: 8rem * 0.04;
  top: 11rem * 0.04;
}

.down {
  width: 15rem * 0.04;
  position: absolute;
  top: 14rem * 0.04;
  right: 10rem * 0.04;
}
}
}
}

.yinhao {
  position: absolute;
  width: 30rem * 0.04;
  height: 24rem * 0.04;
  left: 37rem * 0.04;
  top: 26rem * 0.04;
}

.direction {
  position: absolute;
  height: 36rem * 0.04;
  right: 38rem * 0.04;
  top: 34rem * 0.04;
  font-family: PFM;
  font-style: normal;
  font-weight: 600;
  font-size: 14rem * 0.04;
  line-height: 18rem * 0.04;
  color: #ffffff;
}

.heng {
  position: absolute;
  width: 30rem * 0.04;
  top: 60rem * 0.04;
  right: 38rem * 0.04;
  border-bottom: 2rem * 0.04 solid #fff;
}

.mengTitle {
  position: absolute;
  width: 300rem * 0.04;
  left: 37rem * 0.04;
  top: 70rem * 0.04;
  font-family: PFM;
  font-style: normal;
  font-weight: 600;
  font-size: 24rem * 0.04;
  line-height: 36rem * 0.04;
  text-align: justify;
  color: #ffffff;
}

.kuang {
  position: absolute;
  width: 300rem * 0.04;
  height: 20rem * 0.04;
  left: 38rem * 0.04;
  bottom: 18.5rem * 0.04;
}

.zi {
  position: absolute;
  width: 255rem * 0.04;
  left: 60rem * 0.04;
  bottom: 25rem * 0.04;
}</style>
