<template> </template>

<script>
export default {
  mounted () {
    this.$router.replace({ path: '/single/lite/' })
  }
}
</script>

<style></style>
