import axios from 'axios'

axios.interceptors.request.use(
    config => {
      return config
    },
    error => {
      return Promise.reject(error)
    }
)

// 超时时间
axios.defaults.timeout = 60000

axios.defaults.withCredentials = true

// axios.defaults.baseURL = process.env.VUE_APP_ORIGIN
axios.defaults.baseURL = process.env.VUE_APP_ORIGIN
export default axios
