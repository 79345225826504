<template>
  <div>
    <div class="degreeSelectWra">
      <div class="mainWra">
        <div
          class="mainItem"
          @click="mainAll()"
          :class="{ mainItemSelected: select.degree === [] }"
        >
          全部
        </div>
        <div
          class="mainItem"
          :class="{ mainItemSelected: select.degree.indexOf(index+3) !== -1 }"
          v-for="(item, index) in list"
          :key="index"
          @click="mainClick(index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="footer">
        <div class="footerBtn" @click="clearAll">重置</div>
        <div class="footerBtn" @click="sure">确定</div>
      </div>
    </div>
    <div class="overlay" @click="sure"></div>
  </div>
</template>

<script>
export default {
  name: 'degreeSelect',
  props: {
    select: {},
    type: {}
  },
  data () {
    return {
      isShow: '全部',
      list: ['高中生', '大学生', '研究生']
    }
  },
  mounted () {
    document
      .getElementsByTagName('body')[0]
      .style.setProperty(
        '--label-font-color',
        this.type === 'thesis' ? '#D0AF8B' : '#5BB680'
      )
  },
  methods: {
    clearAll () {
      this.select.degree = []
      this.sure()
    },
    mainClick (index) {
      console.log(this.select.degree.indexOf(index + 3))
      if (this.select.degree.indexOf(index + 3) === -1) {
        this.select.degree.push(index + 3)
        console.log(this.select.degree)
      } else {
        console.log('de')
        this.select.degree.splice(this.select.degree.indexOf(index + 3), 1)
      }
      console.log(this.select.degree)
    },
    mainAll () {
      this.select.degree = []
    },
    sure () {
      this.$emit('degreeSelect', '')
    }
  }
}
</script>

<style scoped lang="scss">
$labelFontColor: var(--label-font-color, #d0af8b);
@media screen and (max-width: 600px){
  .degreeSelectWra {
    width: 100vw;
  }
  .overlay{
    width: 100vw;
  }
}
@media screen and (min-width: 601px){
  .degreeSelectWra {
    width: 400px;
    margin: 0 auto;
  }
  .overlay{
    width: 400px;
  }
}
.degreeSelectWra {
  height: 285rem * 0.04;
  background-color: #f6f8fb;
  z-index: 3;
  position: fixed;
  top: 83rem * 0.04;
  .mainWra {
    width: 100vw;
    height: 180rem * 0.04;
    text-align: left;
    .mainItem {
      width: 100%;
      box-sizing: border-box;
      height: 40rem * 0.04;
      line-height: 40rem * 0.04;
      padding: 0 20rem * 0.04;
      font-size: 12rem * 0.04;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .mainItemSelected {
      background-color: #fff;
      color: $labelFontColor;
    }
  }
  .footer {
    display: flex;
    justify-content: space-around;
    padding: 20rem * 0.04 0 0;
    .footerBtn {
      width: 100rem * 0.04;
      height: 30rem * 0.04;
      border-radius: 8rem * 0.04;
      font-size: 12rem * 0.04;
      line-height: 30rem * 0.04;
      color: #fff;
      background-color: #656d78;
      text-align: center;
      &:last-child {
        background-color: $labelFontColor;
      }
    }
  }
}
.overlay {
  position: fixed;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
}
</style>
