var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.hot ? 'listItemWra' : 'listItemWraNotHot'},[(_vm.hot)?_c('div',{staticClass:"thumbnail"},[_c('img',{staticClass:"img",attrs:{"src":_vm.img,"alt":""},on:{"click":function($event){_vm.dialogVisible = true}}}),(!_vm.data.online)?_c('div',{staticClass:"downImg",staticStyle:{"background-color":"rgba(0,0,0,0.5)"}},[_c('div',{staticClass:"downClass"},[_vm._v("已下架")])]):_vm._e(),(_vm.data.online)?_c('div',{staticClass:"downLoad",style:({
        backgroundColor:
          _vm.type === 'research'
            ? 'rgba(91,182,128,0.7)'
            : 'rgba(208,175,139,0.7)'
      }),on:{"click":function($event){_vm.dialogVisible = true}}},[_vm._v(" 点击下载海报 ")]):_vm._e(),(_vm.data.new)?_c('img',{staticClass:"new",attrs:{"src":require("./icon/new.png"),"alt":""}}):_vm._e()]):_vm._e(),(_vm.hot)?_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":_vm.url,"target":"_blank"}},[_c('div',{staticClass:"mainInfo",class:{ downMainInfo: !_vm.data.online }},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.data.title)+" ")]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"teacher"},[_vm._v(" "+_vm._s(_vm.hot ? '— ' : '')+"导师身份: "+_vm._s(_vm.data.teacher_identity)+" ")])]),_c('div',{staticClass:"tab"},[_vm._v(" "+_vm._s(_vm.directions)+" ")]),_c('div',{staticClass:"lookInfo",style:({ borderColor: _vm.data.online ? '' : '#c0c0c0' })},[_vm._v(" 点击查看详情 ")])])]):_c('div',{staticClass:"mainInfo",class:{ downMainInfo: !_vm.data.online }},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.data.title)+" ")]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"teacher"},[_vm._v("导师身份: "+_vm._s(_vm.data.teacher_identity))])]),_c('div',{staticClass:"tab"},[_vm._v(" "+_vm._s(_vm.directions)+" ")])]),_c('el-dialog',{staticClass:"bigImg",attrs:{"visible":_vm.dialogVisible,"custom-class":"innerDialog","show-close":false},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"downLoadAlert"},[_c('img',{ref:"bigImg",staticClass:"img",attrs:{"src":_vm.img,"alt":""}}),_c('i',{staticClass:"el-icon-circle-close",on:{"click":function($event){_vm.dialogVisible = false}}}),_c('span',{style:({
          backgroundColor:
            _vm.type === 'research' ? 'rgba(91,182,128,1)' : 'rgba(208,175,139,1)'
        })},[_vm._v(" 长按下载海报保存/转发 ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }