<template>
  <div style="position:relative">
    <h1>standard导出选题列表海报</h1>
    <p>
      <el-select
        v-model="select"
        value-key="id"
        @change="change"
        style="width:550px"
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :value="item"
          :label="item.label"
        ></el-option>
      </el-select>
    </p>
    <p><el-button @click="start">确认导出</el-button></p>
    <div id="pic">
      <div id="base">
        <div id="header">
          <div class="meng"></div>
          <img :src="select.file" crossorigin="anonymous" class="background" />
          <img src="./page/yinhao.png" class="yinhao" />
          <div class="text">{{ select.type }}</div>
          <div class="teacher">
            <div>
              <div>
                专属科研推荐信<span style="color:#d7af87">可用于申请 </span>
              </div>
              <div>科研报告<span></span></div>
              <div>10周在线一对一科研学习<span>约20学时 </span></div>
            </div>
            <img src="./page/ying.png" />
          </div>
          <img src="./page/kuang.png" class="kuang" />
          <img src="./page/zi.png" class="zi" />
          <div>
            <div class="degreeleft" />
            <div class="degree">适合年级</div>
            <div class="degreeright" />
          </div>
          <div class="level">高中生｜大学生｜研究生</div>
          <img id="qrcode" src="./page/standard.png" />
          <div class="scan">扫码查看全部课题</div>
        </div>
        <div class="base">
          <div class="divide">
            <div class="line"></div>
            <div class="content">导师背景</div>
          </div>
          <p>
            美国常青藤大学、英国G5、中国双一流院校教授/研究员、副教授/副研究员、助理教授/助理研究员、博士后、博士
          </p>
          <p>
            所有导师皆为国内外核心期刊主编或审稿人及EI/CPCI国际学术会议委员会成员
          </p>
          <div class="divide">
            <div class="line"></div>
            <div class="content">助教背景</div>
          </div>
          <p>所有助教皆为EI/CPCI国际学术会议编辑</p>
          <div class="divide">
            <div class="line"></div>
            <div class="content">课题列表（{{ get_date() }}）</div>
          </div>
        </div>
      </div>

      <div
        class="one"
        v-for="item in topics"
        :key="item.value"
        :id="`one-${item.value}`"
      >
        <div class="type">
          <div></div>
          <span>{{ item.cate }}-{{ item.value }}</span>
        </div>
        <div class="tip">项目名称</div>
        <div class="tipline"></div>
        <div class="tip1">导师背景</div>
        <div class="tipline1"></div>
        <div class="list">
          <el-row :key="topic.title" class="item" v-for="topic in item.list">
            <div class="title">
              {{ topic.title }}
            </div>
            <div class="identity">{{ topic.identity }}</div>
          </el-row>
        </div>
      </div>
    </div>
    <div
      style="position:absolute;top:140px;height:100%;width:100%;background:#fff"
    ></div>
  </div>
</template>

<script>
import axios from '../axios'
import html2canvas from 'html2canvas'
export default {
  name: 'page2',
  data () {
    return {
      options: [
        {
          id: 1,
          type: '理科',
          value: '生物医药,物理学,化学,环境与地理,数学',
          label: '理科-生物医药,物理学,化学,环境与地理,数学',
          file:
            'https://elitebackground.oss-cn-zhangjiakou.aliyuncs.com/poster/S.png'
        },
        {
          id: 2,
          type: '工科',
          value: '人工智能与计算机,建筑与土木工程,化学与环境工程',
          label: '工科-人工智能与计算机,建筑与土木工程,化学与环境工程',
          file:
            'https://elitebackground.oss-cn-zhangjiakou.aliyuncs.com/poster/E1.png'
        },
        {
          id: 3,
          type: '工科',
          value: '电子与电气工程,材料与机械工程,食品与农学',
          label: '工科-电子与电气工程,材料与机械工程,食品与农学',
          file:
            'https://elitebackground.oss-cn-zhangjiakou.aliyuncs.com/poster/E2.png'
        },
        {
          id: 4,
          type: '商科/社科',
          value: '经济与金融,管理学',
          label: '商科/社科-经济与金融,管理学',
          file:
            'https://elitebackground.oss-cn-zhangjiakou.aliyuncs.com/poster/SS1.png'
        },
        {
          id: 5,
          type: '商科/社科',
          value: '社会科学,传播与文化,教育与心理,法学,人文科学',
          label: '商科/社科-社会科学,传播与文化,教育与心理,法学,人文科学',
          file:
            'https://elitebackground.oss-cn-zhangjiakou.aliyuncs.com/poster/SS2.png'
        }
      ],
      select: {},
      topics: [],
      text: '',
      file: ''
    }
  },
  methods: {
    change () {
      // eslint-disable-next-line no-undef
      let x = new XMLHttpRequest()
      x.open('GET', this.select.file, true)
      x.responseType = 'blob'
      let that = this
      x.onload = function (e) {
        that.file = window.URL.createObjectURL(x.response)
      }
      x.send()
    },
    get_date () {
      const date = new Date()
      return `${date.getFullYear()}.${date.getMonth() +
        1}.${date.getDate()}更新`
    },
    start () {
      if (this.file && this.select.value) {
        axios
          .get('previews/get_topiclist', {
            params: {
              types: this.select.value,
              pack: false
            }
          })
          .then(res => {
            this.topics = res.data.topics
            this.exportx()
          })
      }
    },
    exportx () {
      this.$nextTick(() => {
        document.body.style.width = '2024'
        html2canvas(document.querySelector('#pic'), {
          useCORS: true,
          scrollY: -window.pageYOffset
        }).then(canvas => {
          let a = document.createElement('a')
          let url = canvas.toDataURL('image/png')
          a.href = url
          a.setAttribute('target', '_blank')
          a.download = `列表-${this.select.label}.png`
          a.click()
        })
        html2canvas(document.querySelector('#base'), {
          useCORS: true,
          scrollY: -window.pageYOffset
        }).then(canvas => {
          let a = document.createElement('a')
          let url = canvas.toDataURL('image/png')
          a.href = url
          a.setAttribute('target', '_blank')
          a.download = `头图-${this.select.label}.png`
          a.click()
        })
        for (const item of this.topics) {
          let x = `#one-${item.value}`
          html2canvas(document.querySelector(x), {
            useCORS: true,
            scrollY: -window.pageYOffset
          }).then(canvas => {
            let a = document.createElement('a')
            let url = canvas.toDataURL('image/png')
            a.href = url
            a.setAttribute('target', '_blank')
            a.download = `${item.value}.png`
            a.click()
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
#pic {
  width: 375 * 2px;
  padding-bottom: 10 * 2px;
}
#header {
  width: 375 * 2px;
  height: 375 * 2px;
  position: relative;
  .background {
    width: 375 * 2px;
    height: 375 * 2px;
    top: 0;
    z-index: -1;
  }
  .meng {
    width: 375 * 2px;
    height: 375 * 2px;
    position: absolute;
    top: 0 * 2px;
    z-index: 0;
    background: rgba(#000000, 0.65);
  }
  .text {
    position: absolute;
    left: 74 * 2px;
    top: 34 * 2px;
    font-size: 24 * 2px;
    color: #fff;
  }
  .yinhao {
    position: absolute;
    width: 30 * 2px;
    height: 24 * 2px;
    left: 37 * 2px;
    top: 38 * 2px;
  }

  .teacher {
    position: absolute;
    width: 300 * 2px;
    left: 37 * 2px;
    bottom: 110 * 2px;
    border-bottom: 1 * 2px solid #fff;
    border-left: 1 * 2px solid #fff;
    font-family: PFSC;
    font-style: normal;
    font-weight: 600;
    font-size: 10 * 2px;
    line-height: 20 * 2px;
    text-align: right;
    padding-bottom: 5 * 2px;
    padding-right: 65 * 2px;
    box-sizing: border-box;
    color: #ffffff;
    white-space: pre-line;
    > div {
      padding-top: 5 * 2px;
      border-top: 1 * 2px solid #fff;
      padding-left: 20 * 2px;
    }
    span {
      font-size: 8 * 2px;
      width: 45 * 2px;
      display: inline-block;
    }
    img {
      right: 0;
      height: 80 * 2px;
      position: absolute;
      bottom: 10 * 2px;
    }
  }
  .kuang {
    position: absolute;
    width: 300 * 2px;
    height: 20 * 2px;
    left: 38 * 2px;
    top: 92.5 * 2px;
  }
  .zi {
    position: absolute;
    width: 190 * 2px;
    left: 92 * 2px;
    top: 85 * 2px;
  }
  #qrcode {
    position: absolute;
    width: 50 * 2px;
    height: 50 * 2px;
    padding: 1 * 2px;
    position: absolute;
    right: 39 * 2px;
    top: 295 * 2px;
  }
  .degreeleft {
    position: absolute;
    width: 14 * 2px;
    height: 0 * 2px;
    left: 37 * 2px;
    top: 296 * 2px;
    border-top: 1 * 2px solid #ffffff;
  }
  .degree {
    position: absolute;
    width: 48 * 2px;
    height: 20 * 2px;
    left: 54 * 2px;
    top: 286 * 2px;
    font-family: PFSC;
    font-style: normal;
    font-weight: 600;
    font-size: 12 * 2px;
    line-height: 20 * 2px;
    color: #ffffff;
  }
  .degreeright {
    position: absolute;
    width: 166 * 2px;
    height: 0 * 2px;
    left: 106 * 2px;
    top: 296 * 2px;
    border-top: 1 * 2px solid #ffffff;
  }
  .level {
    position: absolute;
    width: 180 * 2px;
    height: 18 * 2px;
    left: 54 * 2px;
    top: 316 * 2px;
    font-family: PFSC;
    font-style: normal;
    font-weight: 600;
    font-size: 10 * 2px;
    line-height: 18 * 2px;
    color: #ffffff;
  }
  .scan {
    position: absolute;
    width: 50 * 2px;
    height: 8 * 2px;
    left: 286 * 2px;
    top: 348 * 2px;

    font-family: PFSC;
    font-style: normal;
    font-weight: 600;
    font-size: 6 * 2px;
    line-height: 8 * 2px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
  }
}

.divide {
  font-family: PFSC;
  font-style: normal;
  font-weight: 600;
  margin-top: 30 * 2px;
  margin-bottom: 20 * 2px;
  color: #d7af87;
  height: 14 * 2px;
  position: relative;
  font-size: 12 * 2px;
  .line {
    top: 7 * 2px;
    border-top: 1 * 2px solid #d7af87;
    position: absolute;
    width: 100%;
  }
  .content {
    margin-left: 10 * 2px;
    background: #fff;
    padding: 0 10 * 2px;
    position: absolute;
    font-family: PFSC;
    font-style: normal;
    font-weight: 600;
    font-size: 12 * 2px;
    line-height: 16 * 2px;
    color: #d7af87;
  }
}
.base {
  width: 375 * 2px;
  background: #ffffff;
  padding: 0 30 * 2px;
  box-sizing: border-box;
  font-family: PFSC;
  font-style: normal;
  font-weight: 600;
  font-size: 10 * 2px;
  line-height: 20 * 2px;
  color: #000000;
  padding-bottom: 1 * 2px;
}
p {
  display: list-item;
  position: relative;
  left: 2em;
  width: calc(100% - 2em);
  line-height: 20 * 2px;
  margin-bottom: 5 * 2px;
}
.one {
  position: relative;
  margin: 0 30 * 2px 22 * 2px;
}
.type {
  height: 12 * 2px;
  box-sizing: border-box;
  padding: 1 * 2px 0;
  > div {
    background: rgba(215, 175, 135, 0.2);
    height: 10 * 2px;
  }
  span {
    font-size: 10 * 2px;
    line-height: 12 * 2px;
    height: 12 * 2px;
    display: block;
    background: #fff;
    width: fit-content;
    padding: 0 15 * 2px 0 5 * 2px;
    position: absolute;
    top: 0 * 2px;
    left: 14 * 2px;
  }
}
.tip {
  position: absolute;
  left: 16 * 2px;
  top: 20 * 2px;
  font-size: 8 * 2px;
}
.tipline {
  position: absolute;
  width: 12 * 2px;
  height: 0 * 2px;
  left: 16 * 2px;
  top: 35 * 2px;
  border-top: 1 * 2px solid #000;
}
.tip1 {
  position: absolute;
  left: 171 * 2px;
  top: 20 * 2px;
  font-size: 8 * 2px;
}
.tipline1 {
  position: absolute;
  width: 12 * 2px;
  height: 0 * 2px;
  left: 171 * 2px;
  top: 35 * 2px;
  border-top: 1 * 2px solid #000;
}
.list {
  width: 100%;
  left: 29 * 2px;
  margin-top: 35 * 2px;
  font-size: 8 * 2px;
  line-height: 16 * 2px;
  box-sizing: border-box;
  .item {
    padding-bottom: 2 * 2px;
  }
  .title {
    width: 150 * 2px;
    display: inline-block;
    padding-right: 5 * 2px;
    padding-left: 16 * 2px;
    line-height: 16 * 2px;
    vertical-align: top;
  }
  .identity {
    width: 125 * 2px;
    display: inline-block;
    line-height: 16 * 2px;
    padding-right: 16 * 2px;
  }
  .item:nth-child(odd) {
    background: rgba(215, 175, 135, 0.2);
  }
}
</style>
