<template>
  <div>
    <img style="width:16rem;height:17.3333rem" :src="img" />
    <img class="img" src="../assets/iap.png" />
    <div class="body">
      <p class="shu"><b>10周1v1海外教授讨论课</b></p>
      <p class="shu"><b>不限课时1v1论文导师辅导</b></p>
      <p class="shu">学术补充包：25课时学术写作强化课+48课时科研基础课</p>
      <div>
        <ititle>项目成果</ititle>
        <div class="msg">
          <div class="line" v-for="item in people" :key="item">
            <p>
              <span>{{ item }}</span>
            </p>
          </div>
        </div>
      </div>
      <div>
        <ititle>师资背景</ititle>
        <p style="text-align:center">
          <strong>Prof. {{ name }}</strong>
        </p>
        <div class="msg">
          <div class="line" v-for="item in teacher" :key="item">
            <p>
              <span>{{ item }}</span>
            </p>
          </div>
        </div>
      </div>
      <div>
        <ititle>研究前沿性</ititle>
        <p>
          <span>{{ frontier }}</span>
        </p>
      </div>
      <div>
        <ititle>适合人群</ititle>
        <p>
          <span
            >本课题涉及到<b>{{ involve }}等</b>方面的知识</span
          >
        </p>
        <p>
          <span
            >适合申请<b>{{ direction }}等</b>相关专业的学生。</span
          >
        </p>
      </div>
      <div>
        <ititle>推荐论文选题</ititle>
        <div v-for="item in recommend" :key="item">
          <p class="shu recommend">
            <span>{{ item }}</span>
          </p>
        </div>
      </div>
      <div>
        <ititle>项目时间安排：11周</ititle>
        <itable></itable>
      </div>
      <div>
        <ititle>补充材料</ititle>
        <div class="file">
          <div class="pdf" @click="download(0)">
            <img src="../assets/pdf文件.png" />
            <div>
              <p>48课时科研基础课详情.pdf</p>
              <span>150.11 KB</span>
            </div>
          </div>
        </div>
        <div class="file">
          <div class="pdf" @click="download(1)">
            <img src="../assets/pdf文件.png" />
            <div>
              <p>25课时学术写作强化课详情.pdf</p>
              <span>156.24 KB</span>
            </div>
          </div>
        </div>
        <div class="file">
          <div class="pdf" @click="download(2)">
            <img src="../assets/pdf文件.png" />
            <div>
              <p>海外教授CV-Prof. {{ name }}.pdf</p>
              <span>121.32 KB</span>
            </div>
          </div>
        </div>
        <div class="supply">
          <p>
            <span>
              (注:补充包所涉及课程，旨在为参与项目的学生强化学术基础，将与1v1正式科研穿插进行，当补充包课程与1v1核心专业指导时间冲突时，以1v1的指导安排为主。
            </span>
          </p>
          <p><span> 补充课程的具体安排安排及内容洋情参考附件。)</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios'
import ititle from './ititle'
import itable from './itable'
export default {
  name: 'App',
  components: {
    ititle,
    itable
  },
  data () {
    return {
      people: [
        '由海外教授提供的高含金量推荐信',
        '独立作者的EI/CPCI收录会议论文',
        '国际认可的论文录用证明',
        '国际名校学术会议的Oral Presentation'
      ],
      name: '',
      OSS: process.env.VUE_APP_OSS,
      img: '',
      involve: '',
      direction: '',
      frontier: '',
      teacher: [],
      recommend: [],
      pdf: []
    }
  },
  mounted () {
    let x = document.body.clientWidth
    document.documentElement.style.fontSize = x / 16 + 'px'
    window.onresize = () => {
      return (() => {
        let x = document.body.clientWidth
        document.documentElement.style.fontSize = x / 16 + 'px'
      })()
    }
    this.pdf = [
      `${this.OSS}/pages/48课时科研基础课详情.pdf`,
      `${this.OSS}/ipages/25课时学术写作强化课详情.pdf`
    ]
  },
  created () {
    let path = this.$route.fullPath
    let uuid = path.split('/').pop()
    axios
      .get('previews/get_ipage', {
        params: {
          uuid: uuid
        }
      })
      .then(res => {
        let data = res.data
        this.frontier = data.frontier
        this.name = data.name
        this.involve = data.involve
        this.direction = data.direction
        this.img =
          `${this.OSS}/ipages/${data.file}.png` + '?t=' + new Date().getTime()
        this.teacher = data.teacher
        this.recommend = data.recommend
        document.title = `${this.direction}-${this.name}`
        this.pdf.push(`${this.OSS}/ipages/${data.file}.pdf`)
      })
      .catch(() => {
        this.$router.push('/ipage')
      })
  },
  methods: {
    download (index) {
      let routeData = this.$router.resolve({
        path: '/pdf',
        query: {
          url: this.pdf[index]
        }
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.img {
  width: 16rem;
  position: absolute;
  top: 0;
  left: 0;
}
/deep/p {
  color: #2b2b2b;
  font-size: 0.68266667rem;
  line-height: 1.6;
}
span {
  font-size: 0.597333rem;
}
.body {
  padding: 0 0.68266667rem;
  text-align: left;
  font-size: 0.68266667rem;
}
.shu {
  display: inline-block;
  padding-left: 0.341333rem;
  border-color: rgb(204, 166, 119);
  border-left-width: 0.128rem;
  border-left-style: solid;
  margin-top: 0.68266667rem;
  color: #595856;
  b {
    font-size: 1.024rem;
  }
}
strong {
  font-size: 0.768rem;
}
.msg {
  padding-top: 0.68266667rem;
  .line {
    color: rgb(102, 102, 102);
    padding-left: 1.49333333rem;
    text-align: justify;
    p {
      display: list-item;
      &::marker {
        unicode-bidi: isolate;
        font-variant-numeric: tabular-nums;
        text-transform: none;
        text-indent: 0px !important;
        text-align: start !important;
        text-align-last: start !important;
      }
    }
  }
}
.recommend span {
  color: #000;
}
.file {
  width: 100%;
  margin-top: 0.68266667rem;
  box-shadow: 0 0.08533333rem 0.34133333rem 0 rgba(0, 0, 0, 0.15);
  border-radius: 0.256rem;
  overflow: hidden;
  position: relative;
  display: inline-block;
}
.pdf {
  padding: 0.68266667rem;
  background-color: #fff;
  display: flex;
  cursor: pointer;
  div {
    flex: 1;
    padding-top: 0.256rem;
    margin-left: 0.68266667rem;
    p {
      font-size: 0.68266667rem;
      color: rgba(0, 0, 0, 0.87);
      line-height: 1.3;
      display: block;
      word-break: break-word;
    }
    span {
      font-size: 0.512rem;
      color: #9b9b9b;
      display: flex;
      margin-top: 0.34133333rem;
    }
  }
  img {
    width: 2.048rem;
    height: 2.56rem;
    background-size: 2.048rem 2.56rem;
    background-repeat: no-repeat;
  }
}

.supply {
  padding: 0.68266667rem 0;
  font-style: italic;
}
</style>
