<template>
  <div class="singleWrapper" id="singleWrapper" @scroll="handleScroll">
    <div class="topWra" ref="topWra">
      <div class="searchWra">
        <input type="text" class="input" v-model="input" placeholder="请输入教授姓名/职位/机构/课时数等关键词" @change="search" />
        <img class="searchIcon" src="../icon/search.png" alt="" />
      </div>
      <div class="select">
        <el-row>
          <el-col :span="10" style="display: flex;justify-content: left;align-items: center">
            <div @click="
              select.showS = !select.showS
                              select.showD = false
                            " class="selecting" :class="{
                  selected: select.showS,
                  hasSelected: select.directions.length > 0
                }">
              学科方向
            </div>
          </el-col>
          <el-col :span="14" style="text-align: right">
            <div @click="selectSameSchool" class="selectHour" :class="{
              hasSelected: sameSchool
            }">
              同校推荐
            </div>
            <!-- <div
                @click="selectHour"
                class="selectHour"
                :class="{
                  hasSelected: hour10
                }"
            >
              10课时
            </div> -->
          </el-col>
        </el-row>
      </div>
    </div>
    <!--    <div style="margin-top: 100rem*0.04"><p v-for="item in select.directions">{{item}}</p></div>-->
    <directionSelect @directionSelect="directionSelect" :type="'thesis'" :select="select" v-if="select.showS">
    </directionSelect>

    <div class="mainWra" ref="mainWra">
      <!--      加载中-->
      <div v-if="(!topics.length && has_next) || firstLoad" class="loading">
        <i class="el-icon-loading"></i>
        <div>加载中，请稍后...</div>
      </div>
      <!--      主体-->
      <listItem v-for="item in topics" :key="item.id" :type="'xdf'" :hot="hot" :data="item"></listItem>
      <!--      下拉到底-->
      <div class="loading" v-if="has_next && !!topics.length">
        <i class="el-icon-loading"></i>
        <div>加载中，请稍后...</div>
      </div>
      <div v-if="!has_next && !!topics.length" class="loading">
        到底了
      </div>
      <div v-if="!has_next && !topics.length && !firstLoad" class="loading">
        没有找到相关选题
      </div>
    </div>
  </div>
</template>

<script>
import listItem from './listItemPro'
import axios from '../../../axios'
import directionSelect from './directionSelect'
import { debounce } from 'lodash'

export default {
  name: 'researchMobile',
  components: {
    listItem,
    directionSelect
  },
  data() {
    return {
      input: '',
      topics: [],
      page: 1,
      select: {
        directions: [],
        degree: [],
        showS: false,
        showD: false
      },
      firstLoad: true,
      hot: true,
      has_next: false,
      has_previous: false,
      pack: true,
      type: 'research',
      skeleton: new Array(6),
      qrcode: '',
      loading: true,
      hour10: '',
      sameSchool: false,
      TargetAudienceStatus: 0
    }
  },
  created() {
    document.title = 'Pro'
    let path = this.$route.path
    if(this.$route.name === "proHighMobile"){
      this.TargetAudienceStatus = 1
      document.title = 'Pro(高中)'
    }
    this.qrcode = path.split('/').pop()
    this.postList()
    // this.getTitle()
  },
  methods: {
    getTitle() {
      axios
        .get('/enterpise/DBS_list', {
          params: {
            type: 'xdf',
            qrcode: this.qrcode
          }
        })
        .then(res => {
          document.title = res.data.title || '定制学术指导'
        })
    },
    postList() {
      this.loading = true
      axios
        .get('/topic/pros', {
          params: {
            keyword: this.input,
            page: this.page,
            admin: true,
            tags: this.select.directions.join(','),
            hours: this.hour10,
            letter: this.sameSchool ? '均支持' : '',
            TargetAudienceStatus: this.TargetAudienceStatus
          }
        })
        .then(response => {
          this.topics.push(...response.data.pros)
          this.has_next = response.data.has_next
          this.has_previous = response.data.has_previous
          this.page++
          this.firstLoad = false
          this.loading = false
        })
    },
    // 学科方向组件回调
    directionSelect() {
      this.select.showS = false
      this.select.showD = false
      this.page = 1
      this.search()
      document.getElementById('singleWrapper').scrollTop = 0
    },
    handleScroll: debounce(function (e) {
      const { scrollTop, clientHeight, scrollHeight } = e.target
      console.log(scrollTop + clientHeight, scrollHeight)
      if (scrollTop + clientHeight >= scrollHeight - 200) {
        if (this.has_next && !this.loading) {
          this.postList()
        }
      }
    }, 200),
    hotOrNot() {
      this.hot = !this.hot
      this.input = ''
      this.topics = []
      this.pack = !this.pack
      this.type = this.pack ? 'research' : 'thesis'
      this.page = 1
      this.select = {
        directions: [],
        degree: [],
        showS: false,
        showD: false
      }
      this.search()
    },
    search() {
      this.page = 1
      this.topics = []
      this.postList()
    },
    selectHour() {
      this.hour10 = this.hour10 ? '' : 10
      this.search()
    },
    selectSameSchool() {
      this.sameSchool = !this.sameSchool
      this.search()
    }
  }
}
</script>

<style scoped lang="scss">
.el-icon-sort {
  margin-right: 7rem * 0.04;
  display: inline-block;
}

@media screen and (max-width: 600px) {
  .singleWrapper {
    width: 100vw;
  }
}

@media screen and (min-width: 601px) {
  .singleWrapper {
    width: 400px;
    margin: 0 auto;
  }
}

.singleWrapper {
  background-color: #f6f8fb;
  height: 100vh;
  overflow: scroll;

  .topWra {
    background-color: #f6f8fb;
    box-sizing: border-box;
    width: 100%;
    position: sticky;
    top: 0;
    padding: 20rem * 0.04 12rem * 0.04 12rem * 0.04;
    border-bottom: #e8e8e8 2rem * 0.04 solid;
    z-index: 3;

    .searchWra {
      position: relative;
      height: 24rem * 0.04;

      .input {
        width: 100%;
        height: 100%;
        font-size: 10rem * 0.04;
        box-sizing: border-box;
        border: #656d78 1rem * 0.04 solid;
        padding-left: 12rem * 0.04;
        display: block;

        &::-webkit-input-placeholder {
          font-size: 10rem * 0.04;
          color: #9b9b9b;
        }
      }

      .searchIcon {
        position: absolute;
        width: 10rem * 0.04;
        height: 11rem * 0.04;
        top: 8rem * 0.04;
        right: 12rem * 0.04;
      }
    }

    .select {
      font-size: 12rem * 0.04;
      text-align: left;
      margin-top: 8rem * 0.04;
      padding: 0 8rem * 0.04;

      div.selected {
        color: #d0af8b;

        &::after {
          transition: 0.3s;
          transform: rotate(180deg);
          transform-origin: 50% 25%;
          //top: -1rem*0.04;
          border-top-color: #d0af8b;
        }
      }

      .hasSelected {
        color: #d0af8b;
        border-color: #d0af8b !important;

        &::after {
          border-top-color: #d0af8b !important;
        }
      }

      .selecting {
        &::after {
          border: #f6f8fb 5rem * 0.04 solid;
          border-top-color: #434a54;
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          transition: 0.3s;
          transform: rotate(0deg);
          transform-origin: 50% 25%;
          position: relative;
          top: 4rem * 0.04;
          left: 8rem * 0.04;
        }
      }

      .selectHour {
        border: #9b9b9b 1px solid;
        padding: 0 4px;
        display: inline-block;
        cursor: pointer;
      }
    }
  }

  .mainWra {
    padding: 0 20rem * 0.04;
  }
}

.loading {
  font-size: 10rem * 0.04;
  text-align: center;
}
</style>
