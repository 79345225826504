<template>
  <section style="height: 1.70667rem; display: flex; justify-content: center;padding:2.048rem 0">
    <section
      style="width: 0.426667rem; background-color: rgb(204, 166, 119); transform: skewX(-20deg);"
    ></section>
    <section
      style=" border-top: 1.70667rem solid transparent; border-right: 0.597333rem solid rgb(204, 166, 119);"
    ></section>
    <section
      style="background-color: rgb(204, 166, 119); color: rgb(255, 255, 255); letter-spacing: 1.5px; font-size: 0.768rem;line-height: 1.62133rem; padding: 0rem 0.426667rem; font-weight: bold;"
    >
      <slot></slot>
    </section>
    <section
      style=" border-bottom: 1.70667rem solid transparent; border-left: 0.597333rem solid rgb(204, 166, 119);"
    ></section>
    <section
      style="width: 0.426667rem; background-color: rgb(204, 166, 119);  transform: skewX(-20deg);"
    ></section>
  </section>
</template>

<script>
export default {}
</script>

<style></style>
