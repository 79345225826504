<template>
  <div class="singleWrapper" id="singleWrapper" @scroll="handleScroll">
    <div class="topWra" ref="topWra">
      <div class="searchWra">
        <input
          type="text"
          class="input"
          v-model="input"
          placeholder="请输入项目名称/适合专业/导师院校/一二级学科标签等关键词"
          @change="search"
        />
        <img class="searchIcon" src="./icon/search.png" alt="" />
      </div>
      <div class="select">
        <el-row>
          <el-col
            :span="10"
            style="display: flex;justify-content: left;align-items: center"
          >
            <div
              @click="
                select.showS = !select.showS
                select.showD = false
              "
              class="selecting"
              :class="{
                selected: select.showS,
                hasSelected: select.directions.length > 0
              }"
            >
              学科方向
            </div>
          </el-col>
          <el-col
            :span="6"
            style="display: flex;justify-content: left;align-items: center"
          >
            <div
              @click="
                select.showD = !select.showD
                select.showS = false
              "
              class="selecting"
              :class="{
                selected: select.showD,
                hasSelected: select.degree.length
              }"
            >
              难度
            </div>
          </el-col>
          <el-col :span="8" style="text-align: right">
            <div class="el-icon-sort" :class="{ selected: hot }"></div>
            <div
              style="display: inline-block"
              @click="hotOrNot"
              :class="{ selected: hot }"
            >
              {{ hot ? '热门' : '其他' }}选题
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!--    <div style="margin-top: 100rem*0.04"><p v-for="item in select.directions">{{item}}</p></div>-->
    <degreeSelect
      @degreeSelect="directionSelect"
      :type="'thesis'"
      :select="select"
      v-if="select.showD"
    ></degreeSelect>
    <directionSelect
      @directionSelect="directionSelect"
      :type="'thesis'"
      :select="select"
      v-if="select.showS"
    ></directionSelect>

    <div class="mainWra" ref="mainWra">
      <!--      加载中-->
      <div v-if="(!topics.length && has_next) || firstLoad" class="loading">
        <i class="el-icon-loading"></i>
        <div>加载中，请稍后...</div>
      </div>
      <!--      主体-->
      <listItem
        v-for="item in topics"
        :key="item.id"
        :type="'thesis'"
        :hot="hot"
        :data="item"
      ></listItem>
      <!--      下拉到底-->
      <div class="loading" v-if="has_next && !!topics.length">
        <i class="el-icon-loading"></i>
        <div>加载中，请稍后...</div>
      </div>
      <div v-if="!has_next && !!topics.length" class="loading">
        到底了
      </div>
      <div v-if="!has_next && !topics.length && !firstLoad" class="loading">
        没有找到相关选题
      </div>
    </div>
  </div>
</template>

<script>
import listItem from './listItem'
import axios from '../../axios'
import directionSelect from './directionSelect'
import degreeSelect from './degreeSelect'
import { debounce } from 'lodash'

export default {
  name: 'researchMobile',
  components: {
    listItem,
    directionSelect,
    degreeSelect
  },
  data () {
    return {
      input: window.sessionStorage.getItem(this.$options.name + '::input'),
      topics: [],
      page: 1,
      select: {
        directions: [],
        degree: [],
        showS: false,
        showD: false
      },
      firstLoad: true,
      hot: true,
      has_next: false,
      has_previous: false,
      pack: true,
      type: 'research',
      skeleton: new Array(6),
      qrcode: '',
      loading: true
    }
  },
  created () {
    document.title = 'Standard项目'
    let path = this.$route.path
    this.qrcode = path.split('/').pop()
    this.postList()
    this.getTitle()
  },
  watch: {
    input () {
      window.sessionStorage.setItem(this.$options.name + '::input', this.input)
    }
  },
  methods: {
    getTitle () {
      axios
        .get('/enterpise/DBS_list', {
          params: {
            type: 'standard',
            qrcode: this.qrcode
          }
        })
        .then(res => {
          document.title = res.data.title || 'Standard项目'
        })
    },
    postList () {
      this.loading = true
      axios
        .post('/enterpise/topic_list', {
          keyword: this.input,
          page: this.page,
          qrcode: this.qrcode,
          tags: this.select.directions,
          degree: Math.max(...this.select.degree),
          type: this.type,
          pack: this.pack
        })
        .then(response => {
          this.topics.push(...response.data.topics)
          this.has_next = response.data.has_next
          this.has_previous = response.data.has_previous
          this.page++
          this.firstLoad = false
          this.loading = false
        })
    },
    // 学科方向组件回调
    directionSelect () {
      this.select.showS = false
      this.select.showD = false
      this.page = 1
      this.search()
      document.getElementById('singleWrapper').scrollTop = 0
    },
    handleScroll: debounce(function (e) {
      const { scrollTop, clientHeight, scrollHeight } = e.target
      console.log(scrollTop + clientHeight, scrollHeight)
      if (scrollTop + clientHeight >= scrollHeight - 200) {
        if (this.has_next && !this.loading) {
          this.postList()
        }
      }
    }, 200),
    hotOrNot () {
      this.hot = !this.hot
      this.input = ''
      this.topics = []
      this.pack = !this.pack
      this.type = this.pack ? 'research' : 'thesis'
      this.page = 1
      this.select = {
        directions: [],
        degree: [],
        showS: false,
        showD: false
      }
      this.search()
    },
    search () {
      this.page = 1
      this.topics = []
      this.postList()
    }
  }
}
</script>

<style scoped lang="scss">
.el-icon-sort {
  margin-right: 7rem * 0.04;
  display: inline-block;
}
@media screen and (max-width: 600px) {
  .singleWrapper {
    width: 100vw;
  }
}
@media screen and (min-width: 601px) {
  .singleWrapper {
    width: 400px;
    margin: 0 auto;
  }
}
.singleWrapper {
  background-color: #f6f8fb;
  height: 100vh;
  overflow: scroll;
  .topWra {
    background-color: #f6f8fb;
    box-sizing: border-box;
    width: 100%;
    position: sticky;
    top: 0;
    padding: 20rem * 0.04 12rem * 0.04 12rem * 0.04;
    border-bottom: #e8e8e8 2rem * 0.04 solid;
    z-index: 3;
    .searchWra {
      position: relative;
      height: 24rem * 0.04;
      .input {
        width: 100%;
        height: 100%;
        font-size: 10rem * 0.04;
        box-sizing: border-box;
        border: #656d78 1rem * 0.04 solid;
        padding-left: 12rem * 0.04;
        display: block;
        &::-webkit-input-placeholder {
          font-size: 10rem * 0.04;
          color: #9b9b9b;
        }
      }
      .searchIcon {
        position: absolute;
        width: 10rem * 0.04;
        height: 11rem * 0.04;
        top: 8rem * 0.04;
        right: 12rem * 0.04;
      }
    }
    .select {
      font-size: 12rem * 0.04;
      text-align: left;
      margin-top: 8rem * 0.04;
      padding: 0 8rem * 0.04;
      div.selected {
        color: #d0af8b;
        &::after {
          transition: 0.3s;
          transform: rotate(180deg);
          transform-origin: 50% 25%;
          //top: -1rem*0.04;
          border-top-color: #d0af8b;
        }
      }
      .hasSelected {
        color: #d0af8b;
        &::after {
          border-top-color: #d0af8b !important;
        }
      }
      .selecting {
        &::after {
          border: #f6f8fb 5rem * 0.04 solid;
          border-top-color: #434a54;
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          transition: 0.3s;
          transform: rotate(0deg);
          transform-origin: 50% 25%;
          position: relative;
          top: 4rem * 0.04;
          left: 8rem * 0.04;
        }
      }
    }
  }
  .mainWra {
    padding: 0 20rem * 0.04;
  }
}
.loading {
  font-size: 10rem * 0.04;
  text-align: center;
}
</style>
