<template>
  <div :class="hot ? 'listItemWra' : 'listItemWraNotHot'">
    <div class="thumbnail" v-if="hot">
      <!--      <div class="img"></div>-->
      <img :src="img" alt="" class="img" @click="dialogVisible = true" />
      <div
        class="downImg"
        v-if="!data.online"
        style="background-color:rgba(0,0,0,0.5);"
      >
        <div class="downClass">已下架</div>
      </div>
      <div
        class="downLoad"
        @click="dialogVisible = true"
        v-if="data.online"
        :style="{
          backgroundColor:
            type === 'thesis' ? 'rgba(208,175,139,0.7)' : 'rgba(91,182,128,0.7)'
        }"
      >
        点击下载海报
      </div>
      <img src="./icon/new.png" alt="" class="new" v-if="data.new" />
    </div>
    <!--    热门、默认主体信息-->
    <a :href="url" target="_blank" style="text-decoration: none" v-if="hot">
      <div class="mainInfo" :class="{ downMainInfo: !data.online }">
        <div class="title">
          {{ data.title }}
        </div>
        <div class="info">
          <div class="teacher">
            {{ hot ? '— ' : '' }}导师身份: {{ data.teacher_identity }}
          </div>
          <div class="diff">
            {{ hot ? '— ' : '' }}适合{{ data.degree < 4 ? '高中生/' : ''
            }}{{ data.degree < 5 ? '大学生/' : '' }}研究生
          </div>
        </div>
        <div class="tab">
          {{ directions }}
        </div>
        <div
          class="lookInfo"
          :style="{ borderColor: data.online ? '' : '#c0c0c0' }"
        >
          点击查看详情
        </div>
      </div>
    </a>
    <!--    其他选题的信息-->
    <div class="mainInfo" v-else :class="{ downMainInfo: !data.online }">
      <div class="title">
        {{ data.title }}
      </div>
      <div class="info">
        <div class="diff">
          适合{{ data.degree < 4 ? '高中生/' : ''
          }}{{ data.degree < 5 ? '大学生/' : '' }}研究生
        </div>
        <div class="teacher">导师身份: {{ data.teacher_identity }}</div>
      </div>
      <div class="tab">
        {{ directions }}
      </div>
    </div>
    <el-dialog
      class="bigImg"
      :visible.sync="dialogVisible"
      custom-class="innerDialog"
      :show-close="false"
    >
      <div class="downLoadAlert">
        <img :src="img" alt="" class="img" ref="bigImg" />
        <i class="el-icon-circle-close" @click="dialogVisible = false"></i>
        <span
          :style="{
            backgroundColor:
              type === 'thesis' ? 'rgba(208,175,139,1)' : 'rgba(91,182,128,1)'
          }"
        >
          长按下载海报保存/转发
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'listItem',
  data () {
    return {
      dialogVisible: false
    }
  },
  props: {
    data: {
      type: Object
    },
    hot: {
      default: true
    },
    type: {}
  },
  mounted () {
    document
      .getElementsByTagName('body')[0]
      .style.setProperty(
        '--label-font-color',
        this.type === 'thesis' ? '#D0AF8B' : '#5BB680'
      )
  },
  computed: {
    directions () {
      const fir = this.data.subject.content
      const dir = this.data.direction
      let res = fir
      for (const item of dir) {
        res = res + ' | ' + item.content
      }
      return res
    },
    img () {
      const day = new Date().getTime()
      if (this.type === 'research') {
        return this.data.img + '?t=' + day
      } else if (this.type === 'thesis') {
        return this.data.pack_img + '?t=' + day
      }
    },
    url () {
      if (this.type === 'research') {
        return this.data.url
      } else if (this.type === 'thesis') {
        return this.data.pack_url
      }
    }
  }
}
</script>

<style scoped lang="scss">
$labelFontColor: var(--label-font-color, #d0af8b);
$labelFontHeight: var(--label-font-color, 79vh);
@media screen and (max-width: 600px) {
  .lookInfo {
    width: 100%;
  }
}
@media screen and (min-width: 601px) {
  .lookInfo {
    width: 215px;
  }
}
.downMainInfo div {
  color: #c0c0c0 !important;
}
.listItemWra {
  box-sizing: border-box;
  min-height: 226rem * 0.04;
  width: 100%;
  background-color: transparent;
  border-bottom: #cdd1d9 1rem * 0.04 solid;
  padding: 20rem * 0.04 0 15rem * 0.04;
  display: flex;
  text-align: left;
  .thumbnail {
    min-width: 117rem * 0.04;
    height: 176rem * 0.04;
    position: relative;
    .downImg {
      width: 117rem * 0.04;
      height: 175rem * 0.04;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .downClass {
        width: 63rem * 0.04;
        height: 23rem * 0.04;
        border-radius: 11rem * 0.04;
        background-color: #5d6068;
        color: #fff;
        font-size: 12rem * 0.04;
        text-align: center;
        line-height: 23rem * 0.04;
      }
    }
    .img {
      width: 117rem * 0.04;
      height: 175rem * 0.04;
      background-color: #434a54;
      object-fit: cover;
      object-position: top;
    }
    .downLoad {
      width: 100%;
      height: 20rem * 0.04;
      line-height: 20rem * 0.04;
      position: absolute;
      bottom: 1px;
      font-size: 10rem * 0.04;
      text-align: center;
      color: #fff;
    }
    .new {
      width: 27rem * 0.04;
      height: 29rem * 0.04;
      position: absolute;
      top: -3rem * 0.04;
      right: 10rem * 0.04;
    }
  }
  .mainInfo {
    min-width: 189rem * 0.04;
    height: 100%;
    overflow: hidden;
    margin-left: 12rem * 0.04;
    .title {
      min-height: 44rem * 0.04;
      width: 100%;
      font-size: 16rem * 0.04;
      line-height: 22rem * 0.04;
      color: #434a54;
      margin-bottom: 10rem * 0.04;
      font-weight: 700;
      overflow: hidden; /*必须结合的属性,当内容溢出元素框时发生的事情*/
      text-overflow: ellipsis; /*可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。*/
      display: -webkit-box; /*必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。*/
      -webkit-line-clamp: 2; /*用来限制在一个块元素显示的文本的行数。*/
      -webkit-box-orient: vertical; /*必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
    }
    .info {
      color: #656d78;
      font-size: 12rem * 0.04;
      line-height: 18rem * 0.04;
    }
    .tab {
      height: 48rem * 0.04;
      margin-top: 12rem * 0.04;
      font-size: 10rem * 0.04;
      color: #9b9b9b;
      line-height: 16rem * 0.04;
    }
    .lookInfo {
      margin-top: 5.5rem * 0.04;
      height: 20rem * 0.04;
      font-size: 12rem * 0.04;
      line-height: 20rem * 0.04;
      border: $labelFontColor 1rem * 0.04 solid;
      box-sizing: border-box;
      color: $labelFontColor;
      text-align: center;
    }
  }
  .bigImg {
    text-align: center;
    .img {
      width: 250rem * 0.04;
      height: auto;
      display: block;
    }
    .downLoadAlert {
      width: fit-content;
      height: fit-content;
      position: relative;
      margin: auto;
      span {
        width: 140rem * 0.04;
        height: 36rem * 0.04;
        font-size: 12rem * 0.04;
        line-height: 36rem * 0.04;
        text-align: center;
        color: #fff;
        position: absolute;
        bottom: 10rem * 0.04;
        bottom: -15rem * 0.04;
        left: 50rem * 0.04;
      }
      .el-icon-circle-close {
        position: absolute;
        top: -25rem * 0.04;
        font-size: 20rem * 0.04;
        font-weight: 900;
        color: #fff;
        right: 0;
      }
    }
    /deep/.el-dialog__header {
      padding: 0;
    }
    /deep/ .innerDialog {
      padding: 0;
      background-color: transparent !important;
      box-shadow: none;
      overflow: hidden;
      width: 100% !important;
      margin: 0 !important;
      height: 100%;
    }
    /deep/ .el-dialog__body {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      height: 100%;
    }
  }
}
.listItemWraNotHot {
  box-sizing: border-box;
  min-height: 80rem * 0.04;
  width: 100%;
  background-color: transparent;
  border-bottom: #cdd1d9 1rem * 0.04 solid;
  padding: 15rem * 0.04 0 12rem * 0.04;
  display: flex;
  text-align: left;
  .mainInfo {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-left: 10rem * 0.04;
    .title {
      font-weight: 700;
      height: 19rem * 0.04;
      width: 100%;
      font-size: 12rem * 0.04;
      line-height: 19rem * 0.04;
      color: #434a54;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:before {
        content: '|';
        color: $labelFontColor;
      }
    }
    .info {
      margin-top: 4rem * 0.04;
      display: flex;
      //justify-content: left;
      //text-align: left;
      //flex-direction: row-reverse;
      color: #656d78;
      font-size: 10rem * 0.04;
      line-height: 20rem * 0.04;
      .diff {
        width: 50%;
        //margin-right: 20rem * 0.04;
      }
      .teacher {
        width: 50%;
      }
    }
    .tab {
      font-size: 8rem * 0.04;
      color: #9b9b9b;
      line-height: 16rem * 0.04;
    }
  }
}
</style>
