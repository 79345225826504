import { render, staticRenderFns } from "./propage copy copy.vue?vue&type=template&id=1529a340&scoped=true&"
import script from "./propage copy copy.vue?vue&type=script&lang=js&"
export * from "./propage copy copy.vue?vue&type=script&lang=js&"
import style0 from "./propage copy copy.vue?vue&type=style&index=0&id=1529a340&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1529a340",
  null
  
)

export default component.exports