<template>
  <router-view></router-view>
</template>

<script>
export default {
  mounted () {
    let x = document.body.clientWidth
    if (x > 600) {
      document.documentElement.style.fontSize = '27px'
    } else {
      document.documentElement.style.fontSize = x / 15 + 'px'
    }
    window.onresize = () => {
      return (() => {
        let x = document.body.clientWidth
        if (x > 600) {
          document.documentElement.style.fontSize = '27px'
        } else {
          document.documentElement.style.fontSize = x / 15 + 'px'
        }
      })()
    }
  }
}
</script>

<style></style>
