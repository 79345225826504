<template>
  <div>
    <!-- <el-button @click="exportx">导出</el-button>
    <el-button @click="upload">上传</el-button>
    <p>{{ url }}</p>
    <p>{{ num }}/{{ total }}</p>
    <el-upload class="upload-demo" :action="uploadUrl" :on-success="success">
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">
        只能上传jpg/png文件，且不超过500kb
      </div>
    </el-upload> -->
    <div id="pic" v-if="render === ''">
      <div id="header">
        <div class="meng"></div>
        <img :src="img" crossorigin="anonymous" class="background" />
        <div class="top">
          <div class="h1"></div>
          <div class="h2" id="h2">{{ subject }}</div>
          <img class="yinhao" src="../assets/yinhao.png" />
          <img class="xinhangdao" src="../assets/xinhangdao.png" />
          <div class="h3">
            一封专属导师推荐信 <br />
            一封完整的科研报告 <br />
            一次完整的科研经历
          </div>
        </div>
        <div>
          <img src="../assets/reyinying.png" class="yinying" />
          <div id="myCanvas" width="1125" height="400"></div>
        </div>
      </div>
      <div id="tail">
        <div class="box">
          <div class="jiao1" />
          <div class="jiao2" />
          <div class="jiao3" />
          <div class="jiao4" />
          <div class="textbox">
            <p class="title">
              {{ title }}
            </p>
            <div class="line" style="margin: 40px 0"></div>
            <div>
              <p style="display: inline-block;vertical-align: top;">
                涉及方向：
              </p>
              <p class="ide">
                {{ direction }}
              </p>
            </div>
            <p style="display: inline-block;vertical-align: top;">
              {{ '导师身份：' }}
            </p>
            <div class="ide">
              <p v-for="item in teacher" :key="item">{{ item }}</p>
            </div>

            <div class="bottom">
              <p>科研D计划 [<b>1V1</b>]</p>
              <div class="line"></div>
              <p class="ms">
                <span style="color:#d5ad85"><b>1v1</b>定制开展 </span> |
                学术入门 | 全年滚动报名
              </p>
              <div id="qrcode" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <img :src="render" v-else style="width:100%" />
  </div>
</template>

<script>
import subjects from './subject.json'
import QRCode from 'qrcodejs2'
import axios from '../axios'
import html2canvas from 'html2canvas'
export default {
  name: 'page1',
  data () {
    return {
      uploadUrl: `${axios.defaults.baseURL}/upload/`,
      subject: '信息系统管理',
      description: '一封专属导师推荐信 一封完整的科研报告 一次完整的科研经历',
      title: '气候变化视角下绿色金融的量化研究',
      direction: '经济学 | 统计学 | 公共管理',
      teacher: ['香港大学浸会大学博士', 'EI/CPCI国际学术会议委员会成员'],
      key: '',
      qrcode: '',
      url: '',
      OSS: process.env.VUE_APP_OSS,
      img: ``,
      total: 0,
      num: 0,
      uuid: '',
      render: ''
    }
  },
  mounted () {
    this.uuid = this.$route.query.uuid
    axios
      .get('previews/get_repage', {
        params: {
          uuid: this.uuid
        }
      })
      .then(res => {
        let data = res.data
        this.title = data.title
        this.img = this.$route.query.img
        this.teacher = []
        this.teacher.push(data.teacher[0])
        this.teacher.push('EI/CPCI国际学术会议委员会成员')
        this.direction = data.direction
        this.subject = data.subject
        this.set()
        this.exportx()
      })
  },
  methods: {
    set () {
      let s = subjects[this.subject]
      let x = 1400 / s.length
      if (x > 250) {
        x = 250
      }
      if (x < 100) {
        x = 100
      }
      let d = document.getElementById('myCanvas')
      d.innerHTML = ''
      let c = document.createElement('canvas')
      c.width = '1125'
      c.height = '400'
      let ctx = c.getContext('2d')
      ctx.font = `bolder ${x}px sans-serif`
      ctx.strokeStyle = '#fff'
      ctx.textBaseline = 'middle' // 设置文本的垂直对齐方式
      ctx.textAlign = 'center' // 设置文本的水平对齐方式
      ctx.fillStyle = 'white'
      ctx.fillRect(0, 0, 1165, 400)
      ctx.globalCompositeOperation = 'destination-out'
      if (this.subject === '能源与动力') {
        ctx.font = `bolder 90px sans-serif`
        ctx.fillText('ENERGY AND POWER', 562, 150)
        ctx.fillText('ENGINEERING', 562, 260)
      } else if (ctx.measureText(s).width > 1000 && s.indexOf(' ') > -1) {
        ctx.font = `bolder 100px sans-serif`
        let x = 150
        if (s.split(' ').length > 2) {
          x = 100
        }
        s.split(' ').forEach(e => {
          ctx.fillText(e, 562, x)
          x += 110
        })
      } else {
        ctx.fillText(s, 562, 200)
      }
      d.appendChild(c)
      let qrcode = `https://${window.location.host}/repage/` + this.uuid
      document.getElementById('qrcode').innerHTML = ''
      // eslint-disable-next-line no-new
      new QRCode('qrcode', {
        text: qrcode,
        width: 150, // 图像宽度
        height: 150, // 图像高度
        colorDark: '#000000', // 前景色
        colorLight: '#ffffff', // 背景色
        typeNumber: 4,
        correctLevel: QRCode.CorrectLevel.H
      })
      document.querySelector('.h1').setAttribute('style', 'display:block')
      document.querySelector('.h2').style.bottom = '120px'
      document.querySelector('.h2').style.fontSize = '135px'
      document.querySelector('.h2').style.width = '450px'
      if (this.subject.length > 3) {
        document.querySelector('.h1').setAttribute('style', 'display:none')
        document.querySelector('.h2').style.bottom = '60px'
        if (this.subject.length % 2 !== 1) {
          document.querySelector('.h2').style.fontSize = '180px'
        }
      } else if (this.subject.length === 2) {
        document.querySelector('.h1').setAttribute('style', 'width:360px')
        document.querySelector('.h2').style.fontSize = '180px'
      }
    },
    exportx () {
      this.$nextTick(() => {
        document.body.style.width = '2024px'
        html2canvas(document.querySelector('#pic'), {
          useCORS: true,
          scrollY: 0,
          height: 2568
        }).then(canvas => {
          document.body.style.width = 'unset'
          this.render = canvas.toDataURL('image/jpeg', 0.9)
          let arr = this.render.split(',')
          let mime = arr[0].match(/:(.*?);/)
          // eslint-disable-next-line no-undef
          let bstr = atob(arr[1])
          let n = bstr.length
          let u8arr = new Uint8Array(n)
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
          }
          // eslint-disable-next-line no-undef
          let param = new FormData()
          param.append(
            'file',
            // eslint-disable-next-line no-undef
            new File([u8arr], `${this.title}.png`, { type: mime })
          )
          param.append('uuid', this.uuid)
          param.append('type', 'repage')
          axios
            .post('previews/upload', param, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(res => {
              window.parent.postMessage(`repage?=${res.data.url}`, '*')
            })
            .catch(() => {
              window.parent.postMessage('error', '*')
            })
        })
      })
    },
    upload () {
      axios
        .get('previews/add_repage', {
          params: {
            key: this.key
          }
        })
        .then(res => {
          this.url = res.data.url
          let pages = res.data.pages
          this.total = pages.length
          let i = 0
          pages.forEach(e => {
            i++
            setTimeout(() => {
              this.direction = e.direction
              this.teacher = []
              this.teacher.push(e.teacher[0])
              this.teacher.push('EI/CPCI国际学术会议委员会成员')
              this.title = e.title
              this.uuid = e.uuid
              this.subject = e.subject
              let img = this.title.replace('/', '_')
              this.img = `${this.OSS}/pages/${img}.png`
              this.set()
              setTimeout(() => {
                this.exportx()
              }, 1000)
            }, i * 5000)
          })
        })
    },
    success (res) {
      this.key = res.key
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  font-family: PFBD;
}
#pic {
  font-weight: 600;
  width: 1125px;
}
#header {
  height: 1500px;
  position: relative;
  #myCanvas {
    position: relative;
  }
  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 1125px;
    height: 1500px;
    z-index: -1;
  }
  .yinying {
    position: absolute;
    bottom: -41px;
    left: -45px;
    height: 483px;
    width: 1217px;
  }
  .top {
    height: 1100px;
    position: relative;
    .yinhao {
      width: 164px;
      position: absolute;
      right: 80px;
      bottom: 250px;
    }
    .xinhangdao{
      height: 60px;
      right: 80px;
      top: 60px;
      position: absolute;
    }
    .h1 {
      position: absolute;
      bottom: 60px;
      left: 110px;
      width: 400px;
      height: 0;
      border-bottom: 15px solid #fff;
    }
    .h2 {
      position: absolute;
      bottom: 120px;
      left: 110px;
      font-size: 135px;
      color: #fff;
      width: 450px;
      line-height: 1.1;
    }
    .h3 {
      position: absolute;
      bottom: 60px;
      right: 110px;
      font-size: 36px;
      color: #fff;
      line-height: 60px;
    }
  }
}
#tail {
  padding: 85px 110px 95px;
}
.box {
  padding: 60px;
  height: 768px;
  position: relative;
}
.jiao1 {
  border-left: 20px solid #000;
  border-top: 20px solid #000;
  width: 40px;
  height: 40px;
  left: 0;
  top: 0;
  position: absolute;
}
.jiao2 {
  border-right: 20px solid #000;
  border-top: 20px solid #000;
  width: 40px;
  height: 40px;
  right: 0;
  top: 0;
  position: absolute;
}
.jiao3 {
  border-left: 20px solid #000;
  border-bottom: 20px solid #000;
  width: 40px;
  height: 40px;
  left: 0;
  bottom: 0;
  position: absolute;
}
.jiao4 {
  border-right: 20px solid #000;
  border-bottom: 20px solid #000;
  width: 40px;
  height: 40px;
  right: 0;
  bottom: 0;
  position: absolute;
}
.title {
  font-size: 44px;
  line-height: 60px;
  min-height: 120px;
}
p {
  font-size: 36px;
  line-height: 56px;
}
.line {
  width: 100px;
  border-top: 5px solid #000;
  margin: 20px 0;
}
b {
  font-family: LemonMilkbold;
}
.bottom {
  position: absolute;
  bottom: 70px;
  width: 785px;
  p {
    font-size: 48px;
    line-height: 48px;
  }
  .ms {
    font-size: 30px;
    line-height: 32px;
  }
  #qrcode {
    position: absolute;
    width: 155px;
    height: 155px;
    margin: auto;
    right: 0;
    bottom: 0;
  }
}
.ide {
  display: inline-block;
  max-width: 605px;
}
.meng {
  height: 1500px;
  width: 1125px;
  position: absolute;
  top: 0px;
  z-index: 0;
  background: rgba(#040000, 0.6);
}
</style>
