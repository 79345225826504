<template>
  <div class="content">
    <p>科研项目</p>
    <div v-for="item in courses" :key="item" @click="xxx(item)" class="item">
      <img :src="`${OSS}/pages/${item}.png`">
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      OSS: process.env.VUE_APP_OSS,
      courses: ['商科', '工科', '理科', '社科']
    }
  },
  methods: {
    xxx (item) {
      document.documentElement.scrollTop = 0
      this.$router.push('repages/' + item)
    }
  }
}
</script>
<style lang="scss" scoped>
.content{
  width: fit-content;
  
  padding: 0 20px;
  margin: auto;
}
  p {
    margin-left: 30px;
    font-size: 48px;
    text-align: center;
  }
.item {
  margin: auto;
  width: fit-content;
  img{
    max-width: 100%;
  }
}
</style>
