<template>
  <div>
    <div style="position:relative">
      <img class="poster" :src="file" />
      <div class="meng"></div>
      <img class="logo" src="http://elitebackground.oss-cn-zhangjiakou.aliyuncs.com/poster/ac/XDF.png" />

      <img src="./page/yinhao.png" class="yinhao" />
      <div class="direction"># {{ direction_tag }}</div>
      <div class="heng"></div>
      <div class="mengTitle">
        {{ title }}
      </div>
      <img src="./page/kuang.png" class="kuang" />
      <img src="./page/zi-xdf.png" class="zi" />
    </div>
    <div class="main">
      <div class="divide">
        <div class="line"></div>
        <div class="content">项目收获</div>
      </div>
      <div class="got">
        <div v-for="item in got" :key="item[0]">
          <img src="./img/gou.png" />
          {{ item[0] }}
          <div class="more">{{ item[1] }}</div>
        </div>
      </div>
      <div class="divide">
        <div class="line"></div>
        <div class="content">项目介绍</div>
      </div>
      <div class="table">
        <div class="tableTitle tableLi yellow">
          <div>Mini版</div>
          <div>标准版</div>
          <div>深度版</div>
        </div>
        <div class="tableLi">
          <div>5周在线</div>
          <div>10周在线</div>
          <div>16周在线</div>
        </div>
        <div class="tableLi yellow">
          <div>5次主导师1对1辅导沟通</div>
          <div>10次主导师1对1辅导沟通</div>
          <div>16次主导师1对1辅导沟通</div>
        </div>
        <div class="tableLi onlyLine">
          <div>视学生表现提供专属科研推荐信</div>
        </div>
        <div class="tableLi yellow">
          <div>独立一作</div>
          <div>独立一作</div>
          <div>独立一作</div>
        </div>
        <div class="tableLi two">
          <div>科研报告符合条件者可享国际普通期刊论文全文写作与投递指导</div>
          <div>
            EI/CPCI/Scopus/ProQuest/Crossref/EBSCO或同等级别索引国际会议论文全文写作与投递指导
          </div>
        </div>
        <div class="tableLi yellow">
          <div>-</div>
          <div>学术演讲机会</div>
          <div>学术演讲机会</div>
        </div>
      </div>
      <div class="divide">
        <div class="line"></div>
        <div class="content">适合人群</div>
      </div>
      <div class="people">
        <div class="content">
          有意提高自身知识水平及学术能力的学生；有意掌握最前沿科研热点及科研方法的学生；有升学意向、跨专业深造的学生。
        </div>
        <div>
          <p>适合年级：{{ get_degree() }}</p>
          <p>
            适合专业：
            <span v-for="item in tags" :key="item" class="tag">
              {{ item }}
            </span>
          </p>
          <p>项目会涉及到{{ method }}。学生需要具备{{ ability }}。</p>
        </div>
      </div>
      <div class="divide">
        <div class="line"></div>
        <div class="content">导师介绍</div>
      </div>
      <div class="teacher">
        <p>推荐信身份：{{ identity }}</p>
        <p>EI/CPCI国际学术会议委员会成员</p>
        <div class="content">
          {{ paper }}
        </div>
      </div>
      <div class="divide">
        <div class="line"></div>
        <div class="content">项目背景</div>
      </div>
      <div class="back">
        {{ back }}
      </div>
      <div class="divide">
        <div class="line"></div>
        <div class="content">项目简介</div>
      </div>
      <div class="introduce">
        {{ introduce }}
      </div>
      <div class="divide">
        <div class="line"></div>
        <div class="content">参考学习内容</div>
      </div>
      <div class="refer">
        <div v-for="(item, index) in tasks" :key="item.title" class="task">
          <div class="left">
            <div class="circle"></div>
            <div class="line" v-if="index !== tasks.length - 1"></div>
          </div>
          <div style="color:#D7AF87;">{{ item.title }}</div>
          <p v-for="value in item.value" :key="value">{{ value }}</p>
        </div>
      </div>
      <div class="tips">（以上任务仅供参考，实际辅导根据定制化要求展开）</div>
      <div class="files">
        附件
        <div class="fileList">
          <img src="./img/suo.png" class="suo" />
          <div class="file" v-for="item in files" :key="item" @click="download(item)">
            <img class="pdf" src="./img/pdf.png" />
            {{ item }}
            <img class="down" src="./img/down.png" />
          </div>
        </div>
      </div>
      <div class="tips">
        （注：补充包所涉及课程，旨在为参与项目的学生强化学术基础，将与1v1正式科研穿插进行，当补充包课程与1v1核心专业指导时间冲突时，以1v1的指导安排为主。
        补充课程的具体安排安排及内容详情参考附件。）
      </div>
    </div>
  </div>
</template>
<script>
import axios from '../axios'

export default {
  data() {
    return {
      got: [
        ['国际认可的独立一作论文写作与投递指导（全文投递）'],
        ['视学生表现提供专属科研推荐信'],
        ['定制化学术研究经历'],
        ['学术演讲机会及演讲证明'],
        ['14课时学术写作课', '（必看）'],
        ['73课时科研基础课', '（选看）']
      ],
      tasks: [
        {
          title: '任务一：掌握查阅文献和研究方法（M/标/深）',
          value: [
            '掌握查阅文献和面向文献学习的方法；',
            '掌握文献管理的方法；',
            '通过查阅文献，学习该方向的研究热点和方向；',
            '掌握快速提炼文献重要信息的方法。'
          ]
        },
        {
          title: '任务二：学习了解准社会交往理论（M/标/深）',
          value: [
            '查找并阅读文献，了解准社会交往理论的含义；',
            '尝试举例说明准社会交往理论。\n'
          ]
        },
        {
          title: '任务三：收集研究案例（标/深）',
          value: ['收集明星与粉丝互动的案例；', '对案例进行整理。']
        },
        {
          title: '任务四：学习从理论视角出发解读媒介现象（标/深）',
          value: ['学习从理论视角出发解读媒介现象。']
        },
        {
          title: '任务五：案例推广（深）',
          value: [
            '将理论进行深化，并推广至更多的市场价之中；',
            '讨论总结理论的演化过程。'
          ],
          duty: '优化全文，并整理添加参考文献'
        },
        {
          title: '任务六：科研总结（M/标/深）',
          value: ['总结科研内容，完成论文提纲；', '整理文献，搜集写作素材。']
        },
        {
          title: '任务七：学术写作（M/标/深）',
          value: ['完成报告/论文各部分内容撰写；', '添加必要参考文献。']
        },
        {
          title: '\n' + '任务八：论文投递（标/深）',
          value: [
            '根据学术会议的要求修改论文格式；',
            '论文的查重与降重；',
            '论文投递与注册；',
            '收获录用证明。'
          ]
        }
      ],
      tags: [],
      task: [],
      files: [
        '项目流程详情-Mini版',
        '项目流程详情-标准版',
        '项目流程详情-深度版',
        '导师简历',
        '专属科研推荐信范本',
        'EI/CPCI国际学术会议录用证明展示',
        '学员案例:论文范例+学员反馈+启航系统展示',
        'FAQ',
        '14学时学术写作课详情（必看）',
        '73学时科研基础课详情（选看）'
      ],
      uuid: '',
      title: '',
      direction_tag: '',
      degree: '',
      method: '',
      ability: '',
      identity: '',
      paper: '',
      back: '',
      introduce: '',
      file: '',
      resume: ''
    }
  },
  created() {
    let path = this.$route.path
    let uuid = path.split('/').pop()
    axios
      .get('previews/get_xdf', {
        params: {
          uuid: uuid
        }
      })
      .then(res => {
        Object.assign(this.$data, res.data)
        document.title = this.title
        this.individualTasks()
      })
      .catch(() => {
        this.$router.push('/xdf')
      })
  },
  mounted() {
    let x = document.body.clientWidth
    document.documentElement.style.fontSize = x / 15 + 'px'
    window.onresize = () => {
      return (() => {
        let x = document.body.clientWidth
        document.documentElement.style.fontSize = x / 15 + 'px'
      })()
    }
  },
  methods: {
    itemsplit(item) {
      item = item.slice(1)
      item = item.map((e, index) => {
        if (index < item.length - 1) {
          e += '；'
        } else e += '。'
        return e
      })
      return item
    },
    get_degree() {
      switch (this.degree) {
        case '5':
          return '研究生'
        case '4':
          return '本科生/研究生'
        default:
          return '高中生/本科生/研究生'
      }
    },
    download(item) {
      item = item.replace('/', '_')
      if (item === '学员案例:论文范例+学员反馈+启航系统展示') {
        item = '学员案例'
      }
      let url = `https://elitebackground.oss-cn-zhangjiakou.aliyuncs.com/poster/ac/${item}.pdf`
      if (item === '导师简历') {
        url = this.resume
      }
      let routeData = this.$router.resolve({
        path: '/pdf2',
        query: {
          url: url
        }
      })
      if (/Android|webOS|iPhone|ipad/i.test(navigator.userAgent)) {
        window.open(routeData.href, '_blank')
      } else {
        window.open(url, '_blank')
      }
    },
    individualTasks() {
      for (let i = 1; i < 5; i++) {
        let titleKey = ['', '二', '三', '四', '五']
        let titleRight = ['', '（M/标/深）', '（标/深）', '（标/深）', '（深）']
        this.tasks[i].title = '任务' + titleKey[i] + '：' + this.task[i - 1][0] + titleRight[i]
        this.tasks[i].value = this.task[i - 1].slice(1)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.main {
  padding: 0 20rem * 0.04;
  padding-bottom: 90rem * 0.04;
}

.poster {
  width: 375rem * 0.04;
  height: 210rem * 0.04;
  object-fit: cover;
  object-position: center;
}

.meng {
  width: 375rem * 0.04;
  height: 210rem * 0.04;
  position: absolute;
  top: 0px;
  z-index: 0;
  background: rgba(#040000, 0.6);
}

.logo {
  position: absolute;
  top: 26rem * 0.04;
  left: 77rem * 0.04;
  height: 30rem * 0.04;
}

.title {
  margin-top: 30rem * 0.04;
  font-family: PFBD;
  font-style: normal;
  font-weight: 600;
  font-size: 16rem * 0.04;
  line-height: 24rem * 0.04;
  text-align: justify;
  color: #555555;
}

.divide {
  font-family: PFBD;
  font-style: normal;
  font-weight: 600;
  font-size: 12rem * 0.04;
  line-height: 16rem * 0.04;
  margin-top: 30rem * 0.04;
  margin-bottom: 10rem * 0.04;
  color: #d7af87;
  height: 14rem * 0.04;
  position: relative;

  .line {
    top: 7rem * 0.04;
    border-top: 1rem * 0.04 solid #d7af87;
    position: absolute;
    width: 100%;
  }

  .content {
    margin-left: 10rem * 0.04;
    background: #fff;
    padding: 0 10rem * 0.04;
    position: absolute;
    font-family: PFBD;
    font-style: normal;
    font-weight: 600;
    font-size: 12rem * 0.04;
    line-height: 16rem * 0.04;
    color: #d7af87;
  }
}

.table {
  width: 100%;
  font-size: 8rem * 0.04;

  .tableLi {
    display: flex;
    line-height: 15rem * 0.04;
    padding: 0 5rem * 0.04;

    div {
      width: 33%;
      box-sizing: border-box;
      padding: 5rem * 0.04 6rem * 0.04;
    }
  }

  .tableLi.onlyLine {
    justify-content: space-around;

    div {
      width: 100%;
      text-align: center;
    }
  }

  .tableLi.two {
    div:last-child {
      width: 66%;
    }
  }

  .tableLi.yellow {
    background-color: rgba(215, 175, 135, 0.2);
  }

  .tableTitle {
    font-size: 11rem * 0.04;
  }
}

.got {
  font-family: PFBD;
  font-style: normal;
  font-weight: 400;
  font-size: 12rem * 0.04;
  line-height: 20rem * 0.04;

  .more {
    font-size: 10rem * 0.04;
    display: inline;
    line-height: 20rem * 0.04;
  }

  span {
    color: #d7af87;
    font-size: 10rem * 0.04;
  }

  img {
    margin-right: 5rem * 0.04;
    width: 9.74rem * 0.04;
  }
}

.people {
  font-family: PFBD;
  font-style: normal;
  font-weight: 400;
  font-size: 12rem * 0.04;
  line-height: 20rem * 0.04;
  color: #000000;

  .content {
    margin-bottom: 5rem * 0.04;
  }

  .tag {
    height: 18rem * 0.04;
    padding: 0 5rem * 0.04;
    line-height: 18rem * 0.04;
    background: #d7af87;
    margin-right: 10rem * 0.04;
    display: inline-block;
    color: #fff;
  }
}

p {
  display: list-item;
  position: relative;
  left: 2em;
  width: calc(100% - 2em);
  line-height: 20rem * 0.04;
}

.teacher {
  font-family: PFBD;
  font-style: normal;
  font-weight: 400;
  font-size: 12rem * 0.04;
  line-height: 20rem * 0.04;
  text-align: justify;
  color: #000000;
  white-space: pre-line;

  .content {
    margin-top: 5rem * 0.04;
  }
}

.back {
  font-family: PFBD;
  font-style: normal;
  font-weight: 400;
  font-size: 10rem * 0.04;
  line-height: 16rem * 0.04;
  text-align: justify;
  color: #000000;
  white-space: pre-line;
}

.introduce {
  font-family: PFBD;
  font-style: normal;
  font-weight: 400;
  font-size: 10rem * 0.04;
  line-height: 16rem * 0.04;
  text-align: justify;
  color: #000000;
  white-space: pre-line;
}

.refer {
  font-family: PFBD;
  font-style: normal;
  font-weight: 500;
  font-size: 10rem * 0.04;
  line-height: 16rem * 0.04;
  color: #000000;

  p {
    line-height: 14rem * 0.04;
    font-weight: 400;
  }

  .re {
    margin-left: 18rem * 0.04;
    margin-bottom: 10rem * 0.04;
  }

  .task {
    padding-left: 18rem * 0.04;
    position: relative;
    padding-bottom: 15rem * 0.04;
  }

  .left {
    position: absolute;
    left: 0;
    top: 0.5em;
    height: 100%;
  }

  .circle {
    height: 5rem * 0.04;
    width: 5rem * 0.04;
    border-radius: 50%;
    background: #ffffff;
    border: 1rem * 0.04 solid #d7af87;
    box-sizing: border-box;
  }

  .line {
    border-left: 1rem * 0.04 solid #d7af87;
    height: 100%;
    width: 0;
    position: absolute;
    left: 2rem * 0.04;
  }
}

.tips {
  font-family: PFBD;
  font-style: normal;
  font-weight: 400;
  font-size: 12rem * 0.04;
  line-height: 16rem * 0.04;
  //text-align: center;
  color: #a7a7a7;
}

.files {
  font-family: PFBD;
  font-style: normal;
  font-weight: 600;
  font-size: 12rem * 0.04;
  line-height: 16rem * 0.04;
  color: #d7af87;
  margin-top: 30rem * 0.04;
  margin-bottom: 10rem * 0.04;

  .fileList {
    border: 1rem * 0.04 solid #d7af87;
    margin-top: 14.5rem * 0.04;
    padding: 10.5rem * 0.04 19.5rem * 0.04 21rem * 0.04;
    position: relative;

    .suo {
      position: absolutez;
      top: -4rem * 0.04;
      left: 45rem * 0.04;
      padding: 0 5rem * 0.04;
      width: 15rem * 0.04;
      background: #fff;
    }

    .file {
      background: #ffffff;
      box-shadow: 0rem * 0.04 0rem * 0.04 10rem * 0.04 rgba(0, 0, 0, 0.25);
      padding: 14rem * 0.04 30rem * 0.04 15rem * 0.04 32rem * 0.04;
      margin-top: 11rem * 0.04;
      font-family: PFBD;
      font-style: normal;
      font-weight: 400;
      font-size: 10rem * 0.04;
      line-height: 14rem * 0.04;
      color: #000000;
      position: relative;

      .pdf {
        width: 20rem * 0.04;
        position: absolute;
        left: 8rem * 0.04;
        top: 11rem * 0.04;
      }

      .down {
        width: 15rem * 0.04;
        position: absolute;
        top: 14rem * 0.04;
        right: 10rem * 0.04;
      }
    }
  }
}

.yinhao {
  position: absolute;
  width: 30rem * 0.04;
  height: 24rem * 0.04;
  left: 37rem * 0.04;
  top: 26rem * 0.04;
}

.direction {
  position: absolute;
  height: 36rem * 0.04;
  right: 38rem * 0.04;
  top: 34rem * 0.04;
  font-family: PFM;
  font-style: normal;
  font-weight: 600;
  font-size: 14rem * 0.04;
  line-height: 18rem * 0.04;
  color: #ffffff;
}

.heng {
  position: absolute;
  width: 30rem * 0.04;
  top: 60rem * 0.04;
  right: 38rem * 0.04;
  border-bottom: 2rem * 0.04 solid #fff;
}

.mengTitle {
  position: absolute;
  width: 300rem * 0.04;
  left: 37rem * 0.04;
  top: 70rem * 0.04;
  font-family: PFM;
  font-style: normal;
  font-weight: 600;
  font-size: 24rem * 0.04;
  line-height: 36rem * 0.04;
  text-align: justify;
  color: #ffffff;
}

.kuang {
  position: absolute;
  width: 300rem * 0.04;
  height: 20rem * 0.04;
  left: 38rem * 0.04;
  bottom: 18.5rem * 0.04;
}

.zi {
  position: absolute;
  width: 255rem * 0.04;
  left: 60rem * 0.04;
  bottom: 25rem * 0.04;
}</style>
