<template>
  <div v-if="sea">
    <div class="sea">
      <el-input v-model="name" @blur="getPage()"> </el-input>
      <span @click="cancel">取消</span>
    </div>
    <div v-for="item in results" :key="item.uuid">
      <div class="item" @click="xxx(item)">
        <img :src="`${OSS}/ipage/${item.file}.png`" />
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="type">
      IAP
      <i class="el-icon-search" @click="sea = true"></i>
    </div>
    <div v-for="item in pages" :key="item.uuid">
      <div class="item" @click="xxx(item)">
        <img :src="`${OSS}/ipage/${item.file}.png`" />
        <span>{{ item.name }}</span>
      </div>
    </div>
    <div class="more" @click="more" v-if="has_next">点击加载更多</div>
  </div>
</template>

<script>
import axios from 'axios'
import { debounce } from 'lodash'
export default {
  data () {
    return {
      OSS: 'https://qihang-prod.oss-cn-zhangjiakou.aliyuncs.com',
      pages: [],
      sea: false,
      name: '',
      results: [],
      has_next: false,
      page: 1,
      load: true
    }
  },
  created () {
    let x = document.body.clientWidth
    document.documentElement.style.fontSize = x / 16 + 'px'
    axios.get('previews/ipages').then(res => {
      this.pages = res.data.pages
      this.has_next = res.data.has_next
      this.load = false
    })
  },
  methods: {
    more () {
      if (this.load) {
        return
      }
      this.page++
      this.load = true
      axios.get('previews/ipages', {
        params: {
          page: this.page
        }
      }).then(res => {
        this.pages = this.pages.concat(res.data.pages)
        this.has_next = res.data.has_next
        this.load = false
      })
    },
    getPage: debounce(function () {
      axios
        .get('previews/ipages', {
          params: {
            name: this.name
          }
        })
        .then(res => {
          this.results = res.data.pages
        })
    }, 500),
    xxx (item) {
      document.documentElement.scrollTop = 0
      this.$router.push('/ipage/' + item.uuid)
    },
    cancel () {
      this.sea = false
      this.results = []
      this.title = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.sea {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 0.85333333rem 0.85333333rem 0.512rem 0.68266667rem;
  background-color: transparent;
  /deep/.el-input__inner {
    display: flex;
    align-items: center;
    background: #f8f8f8;
    border-radius: 0.256rem;
    padding: 0.34133333rem 0.512rem;
    flex: 1;
    height: 1.45066667rem;
    box-sizing: border-box;
    font-family: inherit;
    font-size: 0.4rem;
    line-height: 1.15;
    margin: 0;
  }
  span {
    font-size: 0.59733333rem;
    line-height: 1.45066667rem;
    color: #576b95;
    margin-left: 0.768rem;
    width: 2rem;
  }
}
.type {
  padding: 0.85333333rem 0.85333333rem 0.512rem 0.68266667rem;
  position: relative;
  i {
    position: absolute;
    right: 0;
    padding-right: 0.85333333rem;
    line-height: 1.3;
  }
}
.item {
  padding: 0.256rem 0.68266667rem;
  display: flex;
  align-items: start;
  justify-content: center;
  border-radius: 0.256rem;
  margin-right: 0.68266667rem;
  box-sizing: border-box;
  position: relative;
  &::after {
    position: absolute;
    height: 0.04266667rem;
    width: calc(100% - 4.77867rem);
    content: '';
    bottom: 0.3rem;
    left: 4.096rem;
    background: hsla(210, 8%, 51%, 0.13);
  }
  img {
    width: 2.53066667rem;
    height: 2.73066667rem;
    border-radius: 0.17066667rem;
    background: rgba(22, 107, 199, 0.05);
    -o-object-fit: cover;
    object-fit: cover;
    object-position: top;
  }
  span {
    font-size: 0.68266667rem;
    line-height: 1.024rem;
    color: #333;
    flex: 20;
    padding: 0.9rem;
  }
}
.more {
  display: flex;
  margin: 0 0.34133333rem;
  height: 2.048rem;
  justify-content: center;
  align-items: center;
  font-size: 0.59733333rem;
  color: #617891;
  cursor: pointer;
}
</style>
