<template>
  <div>
    <div id="pic" v-if="render === ''">
      <div id="header">
        <div class="meng"></div>
        <img :src="file" crossorigin="anonymous" class="background" />
        <span>{{ subjectE }}</span>
        <img
          class="logo"
          src="http://elitebackground.oss-cn-zhangjiakou.aliyuncs.com/poster/ac/XDF.png"
        />
        <img src="../assets/xdf1v1.png" class="number" />
      </div>
      <div id="tail">
        <img src="../assets/yinying.png" />
        <div class="box">
          <div class="jiao1" />
          <div class="jiao2" />
          <div class="jiao3" />
          <div class="jiao4" />
          <div class="textbox">
            <p class="title">
              <span style="color:#d5ad85">{{ subject }}</span
              >-{{ title }}
            </p>
            <div class="line"></div>
            <div>
              <p style="display: inline-block;vertical-align: top;">
                涉及方向：
              </p>
              <p class="ide">
                {{ direction }}
              </p>
            </div>
            <p style="display: inline-block;vertical-align: top;">
              {{ '导师身份：' }}
            </p>
            <div class="ide">
              <p v-for="item in teacher" :key="item">{{ item }}</p>
            </div>

            <div class="bottom">
              <p class="fit">适合有学术提升意向的高中生及大学生</p>
              <div class="b1">
                <img src="../assets/zi.png" />
              </div>
              <div class="b3">
                <div id="qrcode" />
                <p>识别二维码了解详情</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img :src="render" v-else style="width:100%" />
  </div>
</template>

<script>
import subjects from './subject.json'
import QRCode from 'qrcodejs2'
import axios from '../axios'
import html2canvas from 'html2canvas'
export default {
  name: 'page2',
  data () {
    return {
      uploadUrl: `${axios.defaults.baseURL}/upload/`,
      subject: '',
      description: '一封专属导师推荐信 一封完整的科研报告 一次完整的科研经历',
      title: '',
      subjectE: '',
      direction: '',
      teacher: [],
      key: '',
      qrcode: '',
      url: '',
      OSS: process.env.VUE_APP_OSS,
      file: '',
      uuid: '',
      total: 0,
      num: 0,
      render: ''
    }
  },
  mounted () {
    this.uuid = this.$route.query.uuid
    axios
      .get('previews/get_xt', {
        params: {
          uuid: this.uuid
        }
      })
      .then(res => {
        Object.assign(this.$data, res.data)
        const data = res.data
        this.subject = data.tags[0]
        this.direction = data.tags.slice(1, 4).join(' | ')
        this.teacher = [data.identity, 'EI/CPCI国际学术会议委员会成员']
        this.set()
        this.set()
        setTimeout(() => {
          this.exportx()
        }, 1000)
      })
  },
  methods: {
    set () {
      this.subjectE = subjects[this.subject]
      let s = this.subjectE
      document
        .querySelector('#header span')
        .setAttribute('style', `font-size:120px`)
      if (s.length > 7) {
        document
          .querySelector('#header span')
          .setAttribute('style', `font-size:100px`)
      }
      if (s.length > 9) {
        document
          .querySelector('#header span')
          .setAttribute('style', `font-size:90px`)
      }
      if (s.length > 11) {
        document
          .querySelector('#header span')
          .setAttribute('style', `font-size:80px`)
      }
      if (s.length > 13) {
        document
          .querySelector('#header span')
          .setAttribute('style', `font-size:75px;line-height: 85px;`)
      }
      if (s.length > 20) {
        document
          .querySelector('#header span')
          .setAttribute('style', `font-size:60px;line-height: 80px;`)
      }
      if (s.length > 30) {
        document
          .querySelector('#header span')
          .setAttribute('style', `font-size:48px;line-height: 72px;`)
      }

      let qrcode = `https://p.eliteqihang.com/xdf/` + this.uuid
      document.getElementById('qrcode').innerHTML = ''
      // eslint-disable-next-line no-new
      new QRCode('qrcode', {
        text: qrcode,
        width: 204, // 图像宽度
        height: 204, // 图像高度
        colorDark: '#000000', // 前景色
        colorLight: '#ffffff', // 背景色
        typeNumber: 4,
        correctLevel: QRCode.CorrectLevel.H
      })
    },

    exportx () {
      this.$nextTick(() => {
        document.body.style.width = '2024px'
        html2canvas(document.querySelector('#pic'), {
          useCORS: true,
          scrollY: 0,
          height: 2436
        }).then(canvas => {
          document.body.style.width = 'unset'
          this.render = canvas.toDataURL('image/jpeg', 0.9)
          let arr = this.render.split(',')
          let mime = arr[0].match(/:(.*?);/)
          // eslint-disable-next-line no-undef
          let bstr = atob(arr[1])
          let n = bstr.length
          let u8arr = new Uint8Array(n)
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
          }
          // eslint-disable-next-line no-undef
          let param = new FormData()
          param.append(
            'file',
            // eslint-disable-next-line no-undef
            new File([u8arr], `${this.title}.png`, { type: mime })
          )
          param.append('uuid', this.uuid)
          param.append('type', '')
          axios
            .post('previews/upload', param, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(res => {
              window.parent.postMessage(`xdf?=${res.data.url}`, '*')
            })
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
* {
  font-family: PFBD;
}
#pic {
  font-weight: 600;
  width: 1125px;
  height: 2436px;
}
#header {
  height: 1125px;
  background-position: bottom;
  position: relative;
  span {
    color: #fff;
    font-size: 105px;
    position: absolute;
    left: 69px;
    bottom: 850px;
    font-weight: bolder;
    line-height: 120px;
    font-family: LemonMilkbold;
    width: 700px;
  }
  .background {
    width: 1125px;
    height: 1125px;
    top: 0;
    position: absolute;
    z-index: -1;
  }
  .logo {
    position: absolute;
    top: 28px;
    left: 60px;
    height: 90px;
  }
  .number {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 1125px;
  }
}
#tail {
  padding: 60px;
  position: relative;
  height: 1311px;
  box-sizing: border-box;
  > img {
    position: absolute;
    top: -44px;
    left: -45px;
  }
}
.box {
  padding: 60px 60px;
  height: 1146px;
  position: relative;
  box-sizing: border-box;
}
.jiao1 {
  border-left: 20px solid #000;
  border-top: 20px solid #000;
  width: 40px;
  height: 40px;
  left: 0;
  top: 0;
  position: absolute;
}
.jiao2 {
  border-right: 20px solid #000;
  border-top: 20px solid #000;
  width: 40px;
  height: 40px;
  right: 0;
  top: 0;
  position: absolute;
}
.jiao3 {
  border-left: 20px solid #000;
  border-bottom: 20px solid #000;
  width: 40px;
  height: 40px;
  left: 0;
  bottom: 0;
  position: absolute;
}
.jiao4 {
  border-right: 20px solid #000;
  border-bottom: 20px solid #000;
  width: 40px;
  height: 40px;
  right: 0;
  bottom: 0;
  position: absolute;
}
.textbox {
  p {
    font-size: 42px;
    line-height: 69px;
  }
  .title {
    font-size: 60px;
    line-height: 78px;
  }
}

.line {
  width: 100px;
  border-top: 5px solid #000;
  margin: 45px 0 36px;
}
b {
  font-family: LemonMilkbold;
  letter-spacing: 8px;
}
.bottom {
  position: absolute;
  bottom: 60px;
  width: 865px;
  height: 400px;
  border-top: 5px solid #000;
  .fit {
    font-weight: 600;
    font-size: 45px;
    line-height: 69px;
    letter-spacing: 0.13em;
    text-align: center;
    margin-top: 20px;
  }
  .b1 {
    position: absolute;
    bottom: 66px;
    img {
      height: 204px;
    }
  }
  .b2 {
    position: absolute;
    top: 110px;
    left: 280px;
    line-height: 77px;
    font-weight: bolder;
  }
  .b3 {
    position: absolute;
    right: 0;
    bottom: 12px;
    width: 210px;
    height: 260px;
    #qrcode {
      position: absolute;
      width: 210px;
      height: 210px;
      margin: auto;
    }
    p {
      position: absolute;
      bottom: 10px;
      font-size: 21px;
      line-height: 30px;
    }
  }
}
.ide {
  display: inline-block;
  width: 645px;
}
.meng {
  height: 1125px;
  width: 1125px;
  position: absolute;
  top: 0px;
  z-index: 0;
  background: rgba(#040000, 0.6);
}
</style>
