<template>
  <div>
    <div id="pdf"></div>
    <div class="remind">长按下载图片保存/转发</div>
  </div>
</template>

<script>
import Pdfh5 from 'pdfh5'
import 'pdfh5/css/pdfh5.css'
export default {
  name: 'pdf',
  mounted () {
    this.pdfh5 = new Pdfh5('#pdf', {
      pdfurl: this.$route.query.url + '?t=1'
    })
    this.pdfh5.on('complete')
  },
  methods: {
    back () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  height: 56px;
  background: #000000;
  position: absolute;
  opacity: 0.7;
  width: 100%;
  z-index: 1000;
  p {
    font-size: 20px;
    color: #ccd1d9;
    text-align: left;
    margin: 17px 1% 13px 14%;
    max-width: 65%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  a {
    position: absolute;
    top: 37%;
    right: 10%;
    img {
      height: 20px;
    }
  }
  .back {
    position: absolute;
    top: 35%;
    left: 7%;
    height: 22px;
  }
}
iframe {
  width: 55%;
  height: 99vh;
  border: none;
  overflow: scroll;
}
/deep/.pdfjs,
.pdfjs .viewerContainer {
  height: 100vh;
}
/deep/.pdfjs .backTop {
  bottom: 110px;
}

.remind{
  position: absolute;
  top: 35px;
  right: 8px;
  background-color: #5BB680;
  width: 138px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #fff;
}
</style>
