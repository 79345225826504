<template>
  <div>
    <!-- <el-button @click="start">导出</el-button> -->
    <!-- <el-button @click="more">上传</el-button> -->
    <div id="pic" v-if="render === ''">
      <div class="top">
        <div class="meng"></div>
        <img
          src="./lsp/L-头.png"
          style="width: 630px;margin:120px 60px;z-index:10;position: relative;"
        />
        <!-- <img
          src="./lsp/10周.png"
          style="top:120px;left:60px;z-index:10;position: absolute;"
        />
        <img
          src="./lsp/L-头镂空.png"
          style="bottom:0px;z-index:10;position: absolute;"
        /> -->
        <div
          crossorigin="anonymous"
          class="background"
          :style="`background-image:url('${file}')`"
        />
      </div>
      <div class="bottom">
        <img
          src="./lsp/yinying.png"
          style="position: absolute;left: -20px;top: -20px;"
        />
        <img src="./lsp/yinhao-lite.png" class="shangyin" />
        <div class="title">{{ title }}</div>
        <div class="direction">{{ tags.join(' | ') }}</div>
        <div class="identity">
          <div class="tip">导师身份</div>
          <div class="text">
            {{ identity }}<br />EI/CPCI国际学术会议委员会成员
          </div>
        </div>
        <div id="qrcode" />
        <div class="ps">扫码查看课题详情</div>
        <img class="psimg" src="./lsp/ps.png" />
        <img src="./lsp/yinhao-lite.png" class="xiayin" />
      </div>
    </div>
    <div id="pict" v-if="render === ''">
      <div class="meng"></div>
      <img
        crossorigin="anonymous"
        class="background"
        :style="`background-image: url(${file});`"
      />
      <img src="./page/yinhao.png" class="yinhao" />
      <div class="direction"># {{ direction_tag }}</div>
      <div class="heng"></div>
      <div class="picttitle">
        {{ title }}
      </div>
      <img src="./page/kuang-lite.png" class="kuang" />
      <img src="./page/zi-lite.png" class="zi" />
    </div>
    <img :src="render" v-else style="width:100%" />
  </div>
</template>

<script>
import axios from '../axios'
import QRCode from 'qrcodejs2'
import uuid from './uuid'
import html2canvas from 'html2canvas'
export default {
  name: 'litepage',
  data () {
    return {
      title: '准社会交往理论视角下粉丝与明星互动关系研究',
      direction_tag: '',
      identity: '北京邮电大学博士/德克萨斯大学奥斯汀分校助理研究员',
      key: '',
      url: '',
      img: '',
      uuid: '',
      total: 0,
      num: 0,
      tags: ['1'],
      render: '',
      file: ''
    }
  },
  mounted () {
    //   this.uuid = this.$route.query.uuid

    //   axios
    //     .get('previews/get_lsp', {
    //       params: {
    //         uuid: this.uuid
    //       }
    //     })
    //     .then(res => {
    //       Object.assign(this.$data, res.data)
    //       this.set()
    //       setTimeout(() => {
    //         this.exportx()
    //       }, 1000)
    //     })
    // },
    this.uuid = this.$route.query.uuid
    axios
      .get('previews/get_lsp', {
        params: {
          uuid: this.uuid
        }
      })
      .then(res => {
        Object.assign(this.$data, res.data)
        if (this.identity.length > 18) {
          this.identity = this.identity.replace('/', '/\n')
        }
        this.set()
        setTimeout(() => {
          this.exportx()
        }, 1000)
      })
  },
  methods: {
    start () {
      uuid.forEach((e, i) => {
        setTimeout(() => {
          this.title = e.a
          this.tags = e.b
          this.file = e.c
          this.identity = e.e
          this.uuid = e.d
          this.set()
          setTimeout(() => {
            this.exportx()
          }, 1000)
        }, i * 4000)
      })
    },
    more () {
      for (let i = 1; i < 250; i++) {
        setTimeout(() => {
          axios
            .get('previews/get_lsp', {
              params: {
                id: i
              }
            })
            .then(res => {
              Object.assign(this.$data, res.data)
              this.set()
              setTimeout(() => {
                this.exportx()
              }, 1000)
            })
        }, i * 4000)
      }
    },
    set () {
      const size = 1100 / this.title.length > 48 ? 48 : 1100 / this.title.length
      document.querySelector('.picttitle').style.fontSize = size + 'px'
      let qrcode = `https://${window.location.host}/lite/` + this.uuid
      document.getElementById('qrcode').innerHTML = ''
      // eslint-disable-next-line
      new QRCode('qrcode', {
        text: qrcode,
        width: 100, // 图像宽度
        height: 100, // 图像高度
        colorDark: '#000000', // 前景色
        colorLight: '#ffffff', // 背景色
        typeNumber: 4,
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    exportx () {
      this.$nextTick(() => {
        let l1 = false
        let l2 = false
        let posturl = ''
        document.body.style.width = '2024px'
        html2canvas(document.querySelector('#pict'), {
          useCORS: true,
          scrollY: 0,
          height: 420
        }).then(canvas => {
          document.body.style.width = 'unset'
          const render = canvas.toDataURL('image/jpeg', 0.9)
          let arr = render.split(',')
          let mime = arr[0].match(/:(.*?);/)
          // eslint-disable-next-line no-undef
          let bstr = atob(arr[1])
          let n = bstr.length
          let u8arr = new Uint8Array(n)
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
          }
          // eslint-disable-next-line no-undef
          let param = new FormData()
          param.append(
            'file',
            // eslint-disable-next-line no-undef
            new File([u8arr], `头-${this.title}.png`, { type: mime })
          )
          param.append('uuid', this.uuid)
          param.append('type', 'lite')
          axios
            .post('previews/upload', param, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(res => {
              l1 = true
              if (l1 && l2) {
                window.parent.postMessage(`lite?=${posturl}`, '*')
              }
            })
        })
        html2canvas(document.querySelector('#pic'), {
          useCORS: true,
          scrollY: 0,
          height: 1624
        }).then(canvas => {
          document.body.style.width = 'unset'
          this.render = canvas.toDataURL('image/jpeg', 0.9)
          let arr = this.render.split(',')
          let mime = arr[0].match(/:(.*?);/)
          // eslint-disable-next-line no-undef
          let bstr = atob(arr[1])
          let n = bstr.length
          let u8arr = new Uint8Array(n)
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
          }
          // eslint-disable-next-line no-undef
          let param = new FormData()
          param.append(
            'file',
            // eslint-disable-next-line no-undef
            new File([u8arr], `${this.title}.png`, { type: mime })
          )
          param.append('uuid', this.uuid)
          param.append('type', '')
          axios
            .post('previews/upload', param, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(res => {
              l2 = true
              posturl = res.data.url
              if (l1 && l2) {
                window.parent.postMessage(`lite?=${res.data.url}`, '*')
              }
            })
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
* {
  font-family: PFM;
}
#pic {
  width: 375 * 2px;
  height: 812 * 2px;
  position: relative;
  .background {
    width: 375 * 2px;
    height: 375 * 2px;
    top: 0;
    z-index: -1;
    background-position: top;
    background-size: cover;
    position: absolute;
  }
  .meng {
    width: 375 * 2px;
    height: 375 * 2px;
    position: absolute;
    top: 0 * 2px;
    z-index: 0;
    background: rgba(#000000, 0.65);
  }
  .top {
    width: 375 * 2px;
    height: 375 * 2px;
    position: relative;
  }
  .bottom {
    width: 375 * 2px;
    height: 437 * 2px;
    position: relative;
    z-index: 100;
    .shangyin {
      top: 30 * 2px;
      left: 30 * 2px;
      position: absolute;
    }
    .title {
      top: 84 * 2px;
      left: 30 * 2px;
      position: absolute;

      width: 315 * 2px;
      font-family: PFM;
      font-style: normal;
      font-weight: 600;
      font-size: 18 * 2px;
      line-height: 24 * 2px;
      text-align: justify;
      color: #555555;
    }
    .direction {
      top: 152 * 2px;
      left: 30 * 2px;
      position: absolute;
      width: 315 * 2px;
      font-family: PFM;
      font-style: normal;
      font-weight: 500;
      font-size: 14 * 2px;
      line-height: 20 * 2px;
      text-align: justify;
      color: #d7af87;
    }
    .identity {
      position: absolute;
      width: 315 * 2px;
      height: 85 * 2px;
      left: 30 * 2px;
      top: 228 * 2px;
      border: 1 * 2px solid #d7af87;
      display: flex;
      align-items: center;
      .tip {
        position: absolute;
        left: 18 * 2px;
        top: -10 * 2px;
        position: absolute;

        font-family: PFM;
        font-style: normal;
        font-weight: 600;
        font-size: 12 * 2px;
        line-height: 20 * 2px;
        /* identical to box height, or 167% */
        text-align: center;
        color: #d7af87;
        background: #fff;
        padding: 0 20px;
      }
      .text {
        margin: 0 24 * 2px;
        top: 16 * 2px;
        font-family: PFM;
        font-style: normal;
        font-weight: 600;
        font-size: 14 * 2px;
        line-height: 20 * 2px;
        color: #555555;
        white-space: pre-line;
      }
    }
    #qrcode {
      position: absolute;
      left: 30 * 2px;
      bottom: 30 * 2px;
    }
    .ps {
      position: absolute;
      left: 90 * 2px;
      bottom: 45 * 2px;
      font-family: PFM;
      font-style: normal;
      font-weight: 600;
      font-size: 8 * 2px;
      display: flex;
      align-items: center;
      color: #555555;
    }
    .psimg {
      position: absolute;
      left: 90 * 2px;
      bottom: 30 * 2px;
    }
    .xiayin {
      position: absolute;
      right: 30 * 2px;
      bottom: 30 * 2px;
      transform: rotate(-180deg);
    }
  }
}
#pict {
  width: 375 * 2px;
  height: 210 * 2px;
  position: relative;
  .background {
    width: 375 * 2px;
    height: 210 * 2px;
    top: 0;
    z-index: -1;
    background-size: cover;
    background-position: center;
  }
  .meng {
    width: 375 * 2px;
    height: 210 * 2px;
    position: absolute;
    top: 0 * 2px;
    z-index: 0;
    background: rgba(#000000, 0.65);
  }
  .yinhao {
    position: absolute;
    width: 30 * 2px;
    height: 24 * 2px;
    left: 37 * 2px;
    top: 26 * 2px;
  }
  .direction {
    position: absolute;
    height: 36 * 2px;
    right: 38 * 2px;
    top: 34 * 2px;
    font-family: PFM;
    font-style: normal;
    font-weight: 600;
    font-size: 14 * 2px;
    line-height: 18 * 2px;
    color: #ffffff;
  }
  .heng {
    position: absolute;
    width: 30 * 2px;
    top: 60 * 2px;
    right: 38 * 2px;
    border-bottom: 2 * 2px solid #fff;
  }
  .picttitle {
    position: absolute;
    width: 300 * 2px;
    left: 37 * 2px;
    top: 70 * 2px;
    font-family: PFM;
    font-style: normal;
    font-weight: 600;
    font-size: 24 * 2px;
    line-height: 36 * 2px;
    text-align: justify;
    color: #ffffff;
  }
  .kuang {
    position: absolute;
    width: 300 * 2px;
    height: 20 * 2px;
    left: 38 * 2px;
    bottom: 18.5 * 2px;
  }
  .zi {
    position: absolute;
    left: 90 * 2px;
    bottom: 25 * 2px;
    width: 190 * 2px;
  }
}
</style>
