<template>
  <div>
    <el-button @click="exportx">导出</el-button>
    <el-button @click="upload">上传</el-button>
    <p>{{ url }}</p>
    <p>{{ num }}/{{ total }}</p>
    <el-upload class="upload-demo" :action="uploadUrl" :on-success="success">
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">
        只能上传jpg/png文件，且不超过500kb
      </div>
    </el-upload>
    <div id="pic">
      <div id="header">
        <img
          :src="`${this.OSS}/ipages/${img}`"
          crossorigin="anonymous"
          class="background"
        />
        <img src="../assets/iap.png" />
      </div>
      <div id="tail">
        <img class="shang" src="../assets/shang.png" />
        <p class="direction">{{ direction }}</p>
        <div class="heng" />
        <p class="name">PROF.<br />{{ name }}</p>
        <p class="teacher">{{ teacher }}<br />{{ position }}</p>
        <img class="tou" crossorigin="anonymous" :src="tou" />
        <img class="xia" src="../assets/xia.png" />
        <div id="qrcode" />
        <img class="wen" src="../assets/wen.png" />
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import axios from '../axios'
import html2canvas from 'html2canvas'
export default {
  name: 'page2',
  data () {
    return {
      uploadUrl: `${axios.defaults.baseURL}/upload/`,
      direction: ['景观设计', '说的的', '是多少', '大撒大撒'],
      teacher: '南加州大学',
      name: 'SUBRAHMANYAM',
      position: '终身教授',
      tou: '',
      key: '',
      qrcode: '',
      url: '',
      OSS: process.env.VUE_APP_OSS,
      img: '',
      uuid: '',
      total: 0,
      num: 0
    }
  },
  mounted () {
    this.tou = `${this.OSS}/ipages/Alfe.png`
    this.img = `${this.direction}-${this.name}.png`
    this.set()
  },
  methods: {
    set () {
      let qrcode = `https://${window.location.host}/ipage/` + this.uuid
      document.getElementById('qrcode').innerHTML = ''
      // eslint-disable-next-line no-new
      new QRCode('qrcode', {
        text: qrcode,
        width: 140, // 图像宽度
        height: 140, // 图像高度
        colorDark: '#000000', // 前景色
        colorLight: '#ffffff', // 背景色
        typeNumber: 4,
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    exportx () {
      this.$nextTick(() => {
        html2canvas(document.querySelector('#pic'), {
          logging: false,
          useCORS: true
        }).then(canvas => {
          let a = document.createElement('a')
          let url = canvas.toDataURL('image/png')
          a.href = url
          a.setAttribute('target', '_blank')
          a.download = this.img
          a.click()
          this.num++
        })
      })
    },
    upload () {
      axios
        .get('previews/add_ipage', {
          params: {
            key: this.key
          }
        })
        .then(res => {
          this.url = res.data.url
          let pages = res.data.pages
          let i = 0
          this.total = pages.length
          pages.forEach(e => {
            i++
            setTimeout(() => {
              this.name = e.name
              this.direction = e.direction.split(' ')
              this.tou = `${this.OSS}/ipages/${this.name}.png`
              this.img = `${e.file}.png`
              if (this.direction.length === 4) {
                let x = this.direction
                this.direction =
                  x[0] + ' | ' + x[1] + '\n' + x[2] + ' | ' + x[3]
              } else {
                let direction = ''
                this.direction.forEach((e, index) => {
                  if (index !== 0) {
                    if (
                      direction.length > 10 &&
                      direction.indexOf('\n') === -1
                    ) {
                      direction += '\n'
                    } else {
                      direction += ' | '
                    }
                  }
                  direction += e
                })
                this.direction = direction
              }
              if (this.direction.indexOf('\n') > -1) {
                document
                  .querySelector('.direction')
                  .setAttribute('style', 'font-size:40px;line-height:50px')
              } else {
                document
                  .querySelector('.direction')
                  .setAttribute('style', 'font-size:45px;line-height:60px')
              }
              if (this.name.length > 10) {
                document.querySelector('.name').style.fontSize = '40px'
              } else {
                document.querySelector('.name').style.fontSize = '50px'
              }
              this.name = this.name.toUpperCase()
              this.teacher = e.teacher
              this.position = e.position
              this.uuid = e.uuid
              this.set()
              setTimeout(() => {
                this.exportx()
              }, 1000)
            }, i * 5000)
          })
        })
    },
    success (res) {
      this.key = res.key
    }
  }
}
</script>
<style lang="scss" scoped>
* {
  font-family: PFSC;
}
#pic {
  width: 1125px;
}
#header {
  height: 1218px;
  background-position: bottom;
  position: relative;
  span {
    color: #fff;
    font-size: 105px;
    position: absolute;
    left: 50px;
    bottom: 860px;
    font-weight: bolder;
    line-height: 100px;
    font-family: LemonMilkbold;
    width: 700px;
  }
  img {
    position: absolute;
  }
}
#tail {
  position: relative;
  height: 1218px;
  padding: 0 150px;
  .shang {
    top: 135px;
    position: absolute;
  }
  .direction {
    text-align: right;
    position: absolute;
    bottom: 975px;
    white-space: break-spaces;
    right: 150px;
    font-size: 45px;
    line-height: 60px;
    color: #cea881;
  }
  .heng {
    width: 100px;
    border-bottom: 6px solid #cea881;
    position: absolute;
    top: 270px;
    right: 150px;
  }
  .name {
    position: absolute;
    left: 585px;
    top: 380px;
    font-size: 50px;
    line-height: 60px;
    font-family: LemonMilkbold;
    color: #cea881;
  }
  .teacher {
    position: absolute;
    left: 585px;
    top: 570px;
    font-size: 36px;
    line-height: 43.2px;
    color: #4d4d4d;
  }
  .xia {
    position: absolute;
    bottom: 405px;
    right: 150px;
  }
  .tou {
    position: absolute;
    top: 370px;
  }
  #qrcode {
    width: 140px;
    height: 140px;
    position: absolute;
    bottom: 150px;
    right: 150px;
  }
  .wen {
    position: absolute;
    bottom: 140px;
    left: 150px;
  }
}
</style>
